import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useField } from '@unform/core';
import {
  CheckboxContainer,
  CheckboxIcon,
  Container,
  InputError,
  StyledCheckbox,
} from './styles';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  isBoolean?: boolean;
  saveAnswer?: () => void;
}

const CheckboxUnform: React.FC<CheckboxProps> = ({
  name,
  children,
  isBoolean,
  saveAnswer,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const [checked, setChecked] = useState(!!defaultValue);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    if (saveAnswer) saveAnswer();
  }, [saveAnswer]);

  useEffect(() => {
    if (isBoolean) {
      setChecked(!!defaultValue);
    }
  }, [defaultValue, isBoolean]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: ref => {
        return ref.checked;
      },
      setValue: (ref, value) => {
        ref.checked = value;
        setChecked(value);
      },
      clearValue: ref => {
        ref.checked = false;
        setChecked(false);
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container className="checkbox">
      <CheckboxContainer>
        <input
          name={name}
          type="checkbox"
          ref={inputRef}
          checked={checked}
          onChange={() => setChecked(!checked)}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          {...rest}
        />
        <StyledCheckbox
          isFocused={isFocused}
          check={!!inputRef.current?.checked}
        >
          <CheckboxIcon viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </CheckboxIcon>
        </StyledCheckbox>
      </CheckboxContainer>
      <span>{children}</span>

      {error && <InputError className="error">{error}</InputError>}
    </Container>
  );
};

export default CheckboxUnform;
