import styled from 'styled-components';
import { lighten } from 'polished';

export const MainContent = styled.div`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 2.8rem 2.8rem;
`;

export const MainContentHeader = styled.header`
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 2.8rem;

  border-bottom: 1px solid #d6d5d3;

  > svg {
    margin-right: 0.88rem;
    margin-bottom: 1.6rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Extrabold';
    color: #1e88e5;
    font-size: 2rem;
    line-height: 2.55rem;
    margin-right: 0.88rem;
    margin-bottom: 1.6rem;
  }

  > div > svg {
    display: block;
    margin: auto;
    position: relative;
    cursor: pointer;
    transition: stroke 0.3s;
  }

  > div > svg {
    stroke: ${lighten(0.16, '#63646A')};

    &:hover {
      stroke: #63646a;
    }
  }

  p {
    font-family: 'MarkPro';
    font-size: 1rem;
    line-height: 1.44rem;
    margin-left: 1.88rem;
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }

  @media (max-width: 430px) {
    justify-content: center;

    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
`;

export const NavigationMenu = styled.div`
  padding-top: 3vh;

  .profile-navBar {
    background: none;
    border: 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
    margin-bottom: 6.11vh;
  }

  .profile-tabs-container {
    overflow: visible !important;
  }

  .MuiTabs-scroller.MuiTabs-fixed {
    overflow: visible !important;
  }

  .profile-tab-item {
    margin-right: 3.22rem;
    > span {
      font-family: 'RedHatDisplay-Regular';
      font-size: 1.22rem;
      color: #000 !important;
      text-transform: none;
    }
  }
`;
