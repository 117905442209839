import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ChaptersLists,
  ContentWrapper,
  IconContent,
  MainContent,
  PlaylistCard,
  TimelineItem,
  VideoInfos,
} from './styles';
import IPlaylistProps from '../../../dtos/IPlaylistProps';
import IJourneyProps from '../../../dtos/IJourneyProps';
import IRequestError from '../../../dtos/IRequestError';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import { ClockIcon } from '../../../assets/svg/SVGComponents';
import VideoContent from '../../../assets/svg/SVGComponents/VideoContent';
import ILessonProps from '../../../dtos/ILessonProps';
import ChapterAccordion from '../../../components/ChapterAccordion';
// import LoadingState from './LoadingState';

interface ILessonItemProps {
  lesson: ILessonProps;
  journey_slug: string;
  playlist_slug: string;
}

// import { Container } from './styles';

const PlaylistAndProgressMobile: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { isSessionExpired, user } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToast();
  const [journeyData, setJourneyData] = useState<IJourneyProps | null>(null);
  const [playlistData, setPlaylistData] = useState<IPlaylistProps | null>(null);
  const [lessonsData, setLessonsData] = useState<ILessonItemProps[] | null>(
    null,
  );
  const [duration, setDuration] = useState(0);
  const [openPlaylistId, setOpenPlaylistId] = useState<string | null>(null);

  const loadTeacherProgressData = useCallback(() => {
    api
      .get(`/journeys/${user.current_lesson.journey_slug}/user-progress`)
      .then(response => {
        setJourneyData(response.data);
        setIsLoading(false);
      })
      .catch(async (err: IRequestError) => {
        setIsLoading(false);

        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops.',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, isSessionExpired, t, user]);

  const handleTimeLeftPlaylist = useCallback((lessons: ILessonItemProps[]) => {
    let timeLeft = 0;

    for (let x = 0; x < lessons.length; x += 1) {
      if (!lessons[x].lesson.watched) {
        timeLeft += lessons[x].lesson.duration;
      }
    }

    return timeLeft;
  }, []);

  useEffect(() => {
    if (
      journeyData &&
      journeyData.playlists &&
      journeyData.playlists.length &&
      user.current_lesson
    ) {
      if (user.current_lesson.group.playlist.slug) {
        const playlist = journeyData.playlists.find(
          playlistItem =>
            playlistItem.slug === user.current_lesson.group.playlist.slug,
        );

        if (playlist) {
          setPlaylistData(playlist);
        } else {
          setPlaylistData(journeyData.playlists[0]);
        }
      }
    }
  }, [journeyData, user.current_lesson]);

  useEffect(() => {
    if (journeyData && playlistData) {
      const groups = playlistData.groups.map(group => {
        return {
          groups: group,
          playlist_slug: playlistData.slug,
          journey_slug: journeyData.slug,
        };
      });

      const lessons = groups
        .flatMap(group => {
          return group.groups.lessons.map(lesson => {
            return {
              lesson,
              journey_slug: group.journey_slug,
              playlist_slug: group.playlist_slug,
            };
          });
        })
        .slice(0, 10);

      const time = handleTimeLeftPlaylist(lessons);
      setDuration(time);
      setLessonsData(lessons);
    }
  }, [handleTimeLeftPlaylist, journeyData, playlistData]);

  const handleActiveLesson = useCallback((lesson: ILessonProps) => {
    if (lesson.watched) {
      return true;
    }
    return false;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/journeys/${user.current_lesson.journey_slug}/user-progress`)
      .then(response => {
        setJourneyData(response.data);
        setIsLoading(false);
      })
      .catch(async (err: IRequestError) => {
        setIsLoading(false);

        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops.',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, isSessionExpired, loadTeacherProgressData, t, user]);

  const handleProgressPlaylist = useCallback((playlist: IPlaylistProps) => {
    if (!playlist.watched_lessons) {
      return 0;
    }

    if (!playlist.lessons_amount) {
      return 0;
    }

    const percentageCompleted =
      (playlist.watched_lessons / playlist.lessons_amount) * 100;
    return Math.round(percentageCompleted);
  }, []);

  const handleToggle = (playlistId: string): void => {
    setOpenPlaylistId(prevId => (prevId === playlistId ? null : playlistId));
  };

  return (
    <ContentWrapper>
      {/* {isLoading && <LoadingState />} */}
      {!isLoading && (
        <MainContent>
          {!!journeyData?.playlists && !!playlistData && lessonsData && (
            <ChaptersLists>
              <h2>{t('h2.playlists_progress')}</h2>
              <ul>
                {journeyData.playlists.map(playlist => (
                  <ChapterAccordion
                    key={playlist.id}
                    title={t(`playlists.${playlist.slug}.title`)}
                    progress={handleProgressPlaylist(playlist)}
                    videoAmount={playlist.lessons_amount}
                    selected={openPlaylistId === playlist.id}
                    isOpen={openPlaylistId === playlist.id}
                    onToggle={() => handleToggle(playlist.id)}
                  >
                    {/* Conteúdo dos vídeos da playlist */}
                    <ul>
                      {playlist.groups.map(groupItem =>
                        groupItem.lessons.map(lesson => (
                          <TimelineItem
                            key={lesson.id}
                            watching={handleActiveLesson(lesson)}
                          >
                            <Link
                              data-testid="link-lesson"
                              to={`/videos/${lesson.journey_slug}/playlists/${playlist.slug}/lessons/${lesson.slug}`}
                            >
                              <PlaylistCard disabled={false}>
                                <IconContent>
                                  <VideoContent width="1.22rem" fill="#fff" />
                                </IconContent>
                                <VideoInfos>
                                  <h3>{t(`lessons.${lesson.slug}.title`)}</h3>

                                  <span>
                                    {t(
                                      `lessons.${lesson.slug}.short_description`,
                                    )}
                                  </span>
                                  <div>
                                    <ClockIcon
                                      width="1.2rem"
                                      fill="none"
                                      stroke="#1E88E5"
                                    />
                                    <span>{`${lesson.duration} min`}</span>
                                  </div>
                                </VideoInfos>
                              </PlaylistCard>
                            </Link>
                          </TimelineItem>
                        )),
                      )}
                    </ul>
                  </ChapterAccordion>
                ))}
              </ul>
            </ChaptersLists>
          )}
        </MainContent>
      )}
    </ContentWrapper>
  );
};

export default PlaylistAndProgressMobile;
