import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  margin: 0;
  overflow: hidden;
  display: flex;
  font-size: 1rem;
`;

export const ImageArea = styled.main`
  position: relative;
  width: 100%;
  img {
    width: 100%;
    height: 101vh;
    object-fit: cover;
    object-position: center;
  }
`;

export const TipBackground = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 3.16rem;
  right: 3.94rem;
  width: 22.94rem;
  height: 12.83rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    object-position: center;
  }
`;

export const Content = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: ${props => (props.isMobile ? '100vw' : '40vw')};

  > svg {
    margin-top: 3.5rem;
  }

  form {
    width: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 3.88rem 6.16rem 0 6.16rem;
    max-width: 100%;
    align-items: stretch;
    text-align: flex-start;

    > svg {
      margin: 0 0 8%;
    }

    > h1 {
      font-family: 'RedHatDisplay-Bold';
      font-size: 1.339rem;
    }

    > span {
      display: flex;
      justify-content: flex-start;
      font-family: 'RedHatDisplay-Regular';
      font-size: 0.88rem;
      text-align: left;
      margin-top: 8.33%;
      margin-bottom: 1.5%;
    }

    > .primary-button {
      margin-top: 3.05rem;
      margin-bottom: 0.88rem;
      height: 3.33rem;
      font-family: 'RedHatDisplay-Bold';
      font-size: 1.33rem;
      border-radius: 0.55rem;
      box-shadow: 0px 3px 15px rgba(255, 152, 0, 0.3);
    }
  }
  footer {
    width: 100%;
    height: 0.778rem;
    background-color: #ff9800;
  }

  @media (max-width: 1226px) {
    max-width: 100vw;
  }
`;

export const SignInArea = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  a {
    font-size: 0.89rem;
    color: #1e88e5;
  }
`;

export const Footer = styled.div`
  flex: 1;
  align-items: flex-end;
  width: 100%;
  height: auto;
`;

export const SideContent = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 100vh !important;

  @media (max-width: 810px) {
    display: none;
  }
`;

export const TopContent = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LanguageButtons = styled.div`
  position: absolute;
  z-index: 1000;
  display: flex;
  top: 3.16rem;
  left: 3.94rem;
  align-items: center;
  color: #ffffff80;

  > button {
    background: none;
    border: 0;
    font-family: 'RedHatDisplay-Regular';
    font-size: 1.22rem;
    color: #ffffff80;

    &.active-language {
      color: #fff;
    }
  }

  > button:first-child {
    margin-right: 0.33rem;
  }

  > button:last-child {
    margin-left: 0.33rem;
  }
`;
