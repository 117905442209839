import styled from 'styled-components';

interface IChapterProps {
  selected: boolean;
}

interface ProgressProps {
  progress: number;
}

export const Wrapper = styled.li<IChapterProps>`
  margin-bottom: 1.72rem;
  padding: 0.8rem;
  font-family: 'RedHatDisplay-Regular';
  border-radius: 0.89rem;
  outline: 2px solid;
  outline-color: ${props => (props.selected ? '#4caf50' : '#fff')};

  > button {
    display: flex;
    flex-direction: row;
    width: 26.56vw;
  }

  opacity: ${props => (props.selected ? '1' : '0.8')};
  transition: opacity 0.3s;
  transition: outline 0.5s;

  &:hover {
    opacity: 1;
    outline: 2px solid;

    outline-color: ${props => (props.selected ? '#4caf50' : '#e2e2e2')};
  }
`;

export const IconWrapper = styled.div`
  width: 4rem;
  height: 4rem;
  border: 2px solid #1e88e5;
  border-radius: 50%;
  background-color: #99bbdd;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    height: 70%;
    object-fit: cover;
    object-position: center;
  }
`;

export const InfoWrapper = styled.div`
  margin-left: 1rem;
  flex: 1;
  display: flex;
  flex-direction: column;
`;
export const InfoBar = styled.div<ProgressProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.559rem;
  font-size: 0.89rem;

  > h3 {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
  }

  > span {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.125rem;
    color: ${props => (props.progress > 0 ? '#FF9800' : '#e2e2e2')};
  }
`;

export const Progress = styled.span`
  height: 0.33rem;
  width: 100%;
  background-color: #e2e2e2;
  border-radius: 5.56rem;
`;

export const Fill = styled.div<ProgressProps>`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #ff9800;
  border-radius: inherit;
  text-align: right;
`;
