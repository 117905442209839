import styled from 'styled-components';

interface IPlaylistProps {
  selected: boolean;
}

interface ProgressProps {
  progress: number;
}

export const AccordionContent = styled.div`
  padding-top: 1.6rem;
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  &.collapsed {
    max-height: 0;
    opacity: 0;
    padding: 0;
  }

  &:not(.collapsed) {
    max-height: 5000px;
    opacity: 1;
  }

  > ul {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
`;

export const ChapterItem = styled.li<IPlaylistProps>`
  margin-bottom: 1.72rem;
  padding: 1.6rem;
  font-family: 'RedHatDisplay-Regular';
  border-radius: 0.89rem;
  outline: 2px solid;
  outline-color: ${props => (props.selected ? '#4caf50' : '#e9e9e9')};
  transition: all 0.3s ease;

  opacity: ${props => (props.selected ? '1' : '0.8')};
  transition: opacity 0.3s;
  transition: outline 0.5s;

  &:hover {
    outline-color: ${props => (props.selected ? '#4caf50' : '#e2e2e2')};
  }

  transition: all 0.3s ease;

  ${AccordionContent} {
    padding: 0 1rem;
  }

  @media (max-width: 540px) {
    ${AccordionContent} {
      padding: 0;
    }
  }
`;

export const AccordionHeader = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .chevron {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: transform 0.3s ease;
    margin-top: 1.4rem;

    svg {
      transition: transform 0.3s ease;
      &.open {
        transform: rotate(180deg);
      }
    }
  }
`;

export const InfoBar = styled.div<ProgressProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.8rem;

    h3 {
      font-family: 'RedHatDisplay-Bold';
      font-size: 1.4rem;
      color: #1e88e5;
    }

    span {
      font-family: 'RedHatDisplay-Bold';
      font-size: 1rem;
      color: ${props => (props.progress > 0 ? '#FF9800' : '#e2e2e2')};
    }
  }
`;

export const Progress = styled.span`
  height: 0.33rem;
  width: 100%;
  background-color: #e2e2e2;
  border-radius: 5.56rem;
`;

export const Fill = styled.div<ProgressProps>`
  height: 100%;
  background-color: #1e88e5;
  border-radius: inherit;
  width: ${props => props.progress}%;
  transition: width 0.5s ease;
`;
