import React from 'react';
import { MainContent } from './styles';
import GridHardware from '../../../components/GridHardware';

const HardwarePage: React.FC = () => {
  return (
    <MainContent>
      <GridHardware />
    </MainContent>
  );
};

export default HardwarePage;
