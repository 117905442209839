import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

interface UserAvatarProps {
  avatarSrc: string;
}

export const Container = styled.header`
  font-size: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 1.4rem;
  background: #fff;
  height: 4.2rem;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  z-index: 5000;
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const LogoArea = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const Menu = styled.div`
  cursor: pointer;
  display: flex;
  position: relative;
  width: auto;

  > button {
    display: flex;
    align-items: center;
  }

  > nav {
    z-index: 1500;
    background: #1e88e5;
    border-radius: 0 0.8rem 0.8rem 0;
    position: fixed;
    top: 4.2rem;
    left: 0;
    bottom: 0; /* Ocupa toda a altura */
    width: 40%;
    min-width: 16rem;
    box-shadow: 0px 10px 30px rgba(85, 85, 85, 0.19);
    opacity: 0;
    visibility: hidden;
    /* transform: translateY(-20px); */
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;

    &.visible {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &.hidden {
      display: none;
    }

    > ul {
      width: 100%;
      padding: 0;
      margin: 0;

      > li {
        white-space: nowrap;
        font-size: 1rem;
        transition: background 0.3s;
        &:first-child {
          border-top-right-radius: 1rem;
        }

        &:last-child {
          border-bottom-left-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;
        }

        &:last-child:hover {
          border-bottom-left-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;
          background: rgba(255, 46, 62, 0.05);
        }

        &:not(:last-child):hover {
          background: rgba(50, 54, 80, 0.05);
        }

        > a {
          width: auto;
          height: 5.1rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 0 1.4rem;

          > svg {
            margin-right: 1rem;
            visibility: visible;
          }
        }
      }
    }
  }
`;

export const NavLinkElm = styled(NavLink)`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;

  > span {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1.25rem;
    line-height: 2rem;
    vertical-align: center;
  }

  &:hover {
    > svg {
      stroke: #ff9800;
      transition: 0.3s;
    }

    span {
      color: #fff;
      transition: 0.3s;
    }
  }

  &.navlink-active {
    cursor: pointer;
    background: #167bd3;
    border-radius: 0.8rem;
    > svg {
      stroke: #ff9800;
    }

    span {
      color: #fff;
    }
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;

  > svg {
    cursor: pointer;
  }
`;

export const ExternalLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  text-decoration: none;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;

  > span {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1.25rem;
    line-height: 2rem;
    vertical-align: center;
  }

  &:hover {
    > svg {
      stroke: #ff9800;
      transition: 0.3s;
    }

    span {
      color: #fff;
      transition: 0.3s;
    }
  }
`;

export const MenuDropdownContainer = styled.div``;

export const LanguageButtons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1rem;

  > button {
    background: none;
    border: 0;
    font-family: 'MarkPro';
    font-size: 1rem;
    color: #c2c2c2;

    &.active-language {
      color: #323232;
    }
  }

  > button:first-child {
    margin-right: 0.33rem;
  }

  > button:last-child {
    margin-left: 0.33rem;
  }
`;

export const DropdownContainer = styled.div`
  cursor: pointer;
  display: flex;
  margin: 0px 0.5em 0px 1.8em;
  position: relative;
  width: auto;

  > button {
    display: flex;
    align-items: center;
  }

  // The 'nav' item corresponds to Dropdown Component
  > nav {
    z-index: 1500;
    background: #ffffff;
    border-radius: 0.8rem;
    position: absolute;
    top: 3rem;
    right: -1rem;
    width: auto;
    min-width: 16rem;
    box-shadow: 0px 10px 30px rgba(85, 85, 85, 0.16);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;

    &.visible {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }

    &.hidden {
      display: none;
    }

    > ul {
      width: auto;
      padding: 0;
      margin: 0;

      > li {
        white-space: nowrap;
        font-size: 1rem;
        transition: background 0.3s;

        &:first-child {
          border-top-left-radius: 0.8rem;
          border-top-right-radius: 0.8rem;
        }

        &:last-child {
          border-bottom-left-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;
        }

        &:last-child:hover {
          border-bottom-left-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;
          background: rgba(255, 46, 62, 0.05);
        }

        &:not(:last-child):hover {
          background: rgba(50, 54, 80, 0.05);
        }

        > a {
          width: auto;
          height: 3.6rem;
          display: flex;
          align-items: center;
          padding: 0 1.55rem;

          > svg {
            margin-right: 1rem;
            visibility: visible;
          }
        }
      }
    }
  }
`;

export const UserAvatar = styled.div<UserAvatarProps>`
  font-size: 1rem;
  height: 2.62rem;
  width: 2.62rem;
  border-radius: 50%;
  background: url(${props => props.avatarSrc}) no-repeat center;
  background-size: cover;
  margin-right: 0.4rem;
`;
