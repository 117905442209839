import styled, { css } from 'styled-components';

interface OverlayProps {
  isPaused: boolean;
  isHovered: boolean;
}

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.1rem;
  overflow: hidden;
  background: #fffefc;
  cursor: pointer;

  &:hover .controls-overlay {
    opacity: 1;
  }

  .react-player {
    object-fit: cover;
  }
`;

// Barra de controle (progress bar) posicionada na parte inferior
export const ControlBar = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 6px;
  background: rgba(255, 255, 255, 1);
`;

export const ControlOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  z-index: 1;
  display: flex;
  justify-content: space-between;

  transition: opacity 0.3s ease;
`;

export const MidControlContainer = styled.div<OverlayProps>`
  background: linear-gradient(transparent, rgba(32, 32, 32, 0.3));
  width: 100%;
  height: 100%;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;

  opacity: ${({ isPaused, isHovered }) => (isPaused || isHovered ? 1 : 0)};
`;

export const BottomControlContainer = styled.div<OverlayProps>`
  background: rgba(32, 32, 32, 0.95);
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  opacity: ${({ isPaused, isHovered }) => (isPaused || isHovered ? 1 : 0)};
`;

export const SliderContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonControlsSection = styled.div`
  padding: 0 0.8rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      text-align: center;
      color: #fffefc;
      font-family: 'RedHatDisplay-Bold';
      font-size: 0.88rem;
    }
  }
`;

export const ProgressBar = styled.div`
  flex-grow: 1;
  input {
    width: 100%;
    height: 4px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 2px;
    appearance: none;

    &::-webkit-slider-thumb {
      appearance: none;
      width: 15px;
      height: 15px;
      background: #1e88e5;
      border-radius: 50%;
      cursor: pointer;
    }
  }
`;

export const VolumeControl = styled.div`
  display: flex;
  align-items: center;
  gap: 0.625rem;
  color: white;

  input {
    width: 80px;
  }
`;

export const SeekButton = styled.button`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: scale(1.1);
  }
`;

export const BottomControls = styled.div`
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  padding: 1.5rem;
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const ControlButtonStyles = css`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.2s ease;
  width: 3.5rem;
  height: 3.5rem;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const PlayPauseButton = styled.button`
  ${ControlButtonStyles}
  background: ${({ isPlaying }: { isPlaying: boolean }) =>
    isPlaying ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
`;

export const ProgressBarContainer = styled.input.attrs({ type: 'range' })`
  width: 100%;
  height: 0.5rem;
  background: rgba(255, 255, 255, 0.4);
  border-radius: 0.1rem;
  position: relative;
  cursor: pointer;
`;

export const ButtonControlBar = styled.div`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  transition: all 0.2s ease;
  width: 3.5rem;
  height: 3.5rem;

  &:hover {
    svg {
      fill: lighten(0.8, '#63646A');
    }
  }
`;

export const VolumeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-center: center;
  margin-left: 1rem;
  position: relative;

  > button {
    display: flex;
    align-items: center;
    justify-center: center;
  }

  input[type='range'] {
    position: absolute;
    opacity: 0;
    transition: opacity 0.3s;
  }

  &:hover input[type='range'] {
    opacity: 1;
  }

  @media (max-width: 1024px) {
    input[type='range'] {
      opacity: 1;
    }
  }
`;

export const VolumeSlider = styled.input.attrs({ type: 'range' })`
  margin-left: 1.6rem;
  width: 5.5rem;
  position: absolute;
  top: 50%; /* posiciona acima do botão */
  left: 0;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  @media (max-width: 1024px) {
    width: 4rem;
    margin-left: 1.8rem;
  }
`;

export const SpeedControl = styled.select`
  background: rgba(255, 255, 255, 0.1);
  border: none;
  border-radius: 0.5rem;
  padding: 0.4rem 0.5rem;
  color: #fff;
  cursor: pointer;
  margin-right: 0.8rem;

  option {
    background: #323232;
    color: #fff;
  }

  @media (max-width: 425px) {
    padding: 0.2rem 0.3rem;
    margin-right: 0.4rem;
  }

  @media (max-width: 375px) {
    padding: 0;
    margin-right: 0;
  }
`;
