/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const fullscreenIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g strokeLinecap="round" strokeLinejoin="round" clipPath="url(#a)">
      <path
        strokeWidth={1.5}
        d="M18.364 5.636a9 9 0 0 1 0 12.728 9 9 0 1 1 0-12.728Z"
      />
      <path
        strokeWidth={1.528}
        d="M16 14.431H8a1 1 0 0 1-1-1V9.57a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3.861a1 1 0 0 1-1 1.001Z"
      />
      <path strokeWidth={1.394} d="M9.5 17h5" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default fullscreenIcon;
