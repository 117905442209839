// É preciso alterar simultaneamente com com ChapterAnd Progress pois são praticamente as mesma pagina porém TrailsAndChapters está em formato de Tabs e essa não.
import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {
  ContentWrapper,
  Fill,
  InfoBar,
  InfoPlaylist,
  ListWrapper,
  MainContent,
  MainContentHeader,
  MainHeadContent,
  RightSideBarContent,
  Progress,
  Title,
  TitleHeader,
  ProgressList,
  ChapterItem,
  IconWrapper,
  InfoWrapper,
  IconWrapperTrail,
} from './styles';

import IRequestError from '../../../dtos/IRequestError';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import {
  ArrowBackIcon,
  DotContent,
  ProjectsIcon,
} from '../../../assets/svg/SVGComponents';
import ITrailsProps from '../../../dtos/ITrailsProps';
import IActivityProps from '../../../dtos/IActivitiesProps';
import IChapterProps from '../../../dtos/IChapterProps';
import ListContentActivity from '../../../components/ListContentActivity';
import ListContentChapter from '../../../components/ListContentChapter';
import LoadingState from './LoadingState';

// import { Container } from './styles';

const ChaptersAndProgress: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { isSessionExpired, user } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToast();

  const { trail_slug, chapter_slug } = useParams();
  const [trailsData, setTrailsData] = useState<ITrailsProps | null>(null);
  const [chaptersData, setChaptersData] = useState<IChapterProps | null>(null);
  const [duration, setDuration] = useState(0);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  useEffect(() => {
    if (isTabletOrMobile || isPortrait) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isPortrait, isTabletOrMobile]);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    let trailSlug = '';
    if (trail_slug) trailSlug = trail_slug;
    else trailSlug = user.current_activity.chapters[0].trails[0].slug;

    api
      .get(`/trails/${trailSlug}/progress`)
      .then(response => {
        setTrailsData(response.data);
        setIsLoading(false);
      })
      .catch(async (err: IRequestError) => {
        setIsLoading(false);

        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops.',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, t, trail_slug, user.current_activity]);

  const handleTimeLeftPlaylist = useCallback(
    (chapter: IChapterProps): number => {
      if (chapter.type === 'lesson') {
        let timeLeft = 0;

        for (let x = 0; x < chapter.lessons.length; x += 1) {
          timeLeft += chapter.lessons[x].duration;
        }

        return timeLeft;
      }
      let timeLeft = 0;

      for (let x = 0; x < chapter.activities.length; x += 1) {
        timeLeft += chapter.activities[x].duration;
      }

      return timeLeft;
    },
    [],
  );

  const handleButtonChapter = useCallback((chapter: IChapterProps): void => {
    setChaptersData(chapter);
  }, []);

  useEffect(() => {
    if (trailsData && chaptersData) {
      const time = handleTimeLeftPlaylist(chaptersData);

      setDuration(time);
    }
  }, [chaptersData, handleTimeLeftPlaylist, trailsData]);

  useEffect(() => {
    if (trailsData && trailsData.chapters) {
      let chapterInput;
      if (chapter_slug) {
        const chapterSelected = trailsData.chapters.find(
          ch => ch.slug === chapter_slug,
        );
        if (chapterSelected) chapterInput = chapterSelected;
      } else if (!chapterInput) {
        const chapter = trailsData.chapters.find(
          ch => ch.slug === user.current_activity.chapters[0].slug,
        );
        if (chapter) chapterInput = chapter;
        // eslint-disable-next-line prefer-destructuring
        else chapterInput = trailsData.chapters[0];
      }

      if (chapterInput) setChaptersData(chapterInput);
    }
  }, [chapter_slug, trailsData, user]);

  const handleProgressChapters = useCallback(
    (chapter: IChapterProps) => {
      if (!chapter.done_activities) {
        return 0;
      }
      if (!trailsData?.chapters.length) {
        return 0;
      }
      let percentageCompleted = 0;
      if (chapter.type === 'lesson') {
        percentageCompleted =
          (chapter.done_activities / chapter.lessons.length) * 100;
      }
      if (chapter.type === 'activity') {
        percentageCompleted =
          (chapter.done_activities / chapter.activities.length) * 100;
      }

      return Math.round(percentageCompleted);
    },
    [trailsData],
  );

  const handleProgressTrails = useCallback(
    (trail: ITrailsProps) => {
      let doneActivies = 0;
      trail.chapters.forEach(chapter => {
        if (!chapter.done_activities) {
          return;
        }
        if (!trailsData?.chapters.length) {
          return;
        }

        if (chapter.type === 'lesson') {
          doneActivies += chapter.done_activities;
        }
        if (chapter.type === 'activity') {
          doneActivies += chapter.done_activities;
        }
      });

      return Math.round((doneActivies / trail.chapters.length) * 100);
    },
    [trailsData],
  );

  return (
    <>
      {isLoading && <LoadingState />}
      {!isLoading && (
        <ContentWrapper>
          <MainContent>
            <MainContentHeader>
              {!isMobile && (
                <Link to={-1 as any}>
                  <ArrowBackIcon
                    width="1.22rem"
                    stroke="#1E88E5"
                    strokeWidth={3}
                    fill="none"
                  />
                  {t('navlinks.back')}
                </Link>
              )}
              <TitleHeader>
                <ProjectsIcon width="2rem" stroke="#FF9800" strokeWidth={2} />
                <h1>{t('h1.projects')}</h1>
              </TitleHeader>
            </MainContentHeader>
            <MainHeadContent>
              {trailsData && chaptersData && (
                <Title>
                  <DotContent width="2.22rem" />
                  {trailsData.type === 'chapter' ? (
                    <h1>{t(`trails.${trailsData.slug}.title`)}</h1>
                  ) : (
                    <h1>{t(`chapters.${chaptersData.slug}.title`)}</h1>
                  )}
                </Title>
              )}

              {chaptersData && (
                <InfoPlaylist>
                  <div />
                  {trailsData && (
                    <span>
                      <Trans
                        i18nKey="spans.time_left"
                        values={{ time: duration }}
                      />
                    </span>
                  )}
                </InfoPlaylist>
              )}
            </MainHeadContent>
            {chaptersData && trailsData && (
              <ListWrapper>
                {trailsData.type === 'activity' && (
                  <ListContentActivity
                    chapters={chaptersData}
                    trail_slug={trailsData.slug}
                  />
                )}

                {trailsData.type === 'chapter' && (
                  <ListContentChapter
                    chapters={chaptersData}
                    trails={trailsData}
                  />
                )}
              </ListWrapper>
            )}
          </MainContent>

          {!isMobile && (
            <RightSideBarContent>
              {!!trailsData &&
                !!chaptersData &&
                !!trailsData.chapters.length && (
                  <ProgressList>
                    <h2>{t('h2.playlists_progress')}</h2>
                    <ul>
                      {trailsData.type === 'activity' &&
                        trailsData.chapters.map(chapter => (
                          <ChapterItem
                            selected={chapter.id === chaptersData.id}
                            key={chapter.id}
                          >
                            <button
                              type="button"
                              onClick={() => handleButtonChapter(chapter)}
                            >
                              <IconWrapper>
                                <img src={chapter.icon_url} alt="icon" />
                              </IconWrapper>

                              <InfoWrapper>
                                <InfoBar
                                  progress={handleProgressChapters(chapter)}
                                >
                                  <h3>{t(`chapters.${chapter.slug}.title`)}</h3>
                                  <span>
                                    {handleProgressChapters(chapter)}%
                                  </span>
                                </InfoBar>

                                <Progress>
                                  <Fill
                                    progress={handleProgressChapters(chapter)}
                                  />
                                </Progress>
                              </InfoWrapper>
                            </button>
                          </ChapterItem>
                        ))}

                      {trailsData.type === 'chapter' && (
                        <ChapterItem selected key={trailsData.id}>
                          <button type="button">
                            <IconWrapperTrail>
                              <img src={trailsData.image_url} alt="icon" />
                            </IconWrapperTrail>

                            <InfoWrapper>
                              <InfoBar
                                progress={handleProgressTrails(trailsData)}
                              >
                                <h3>{t(`trails.${trailsData.slug}.title`)}</h3>
                                <span>{handleProgressTrails(trailsData)}%</span>
                              </InfoBar>

                              <Progress>
                                <Fill
                                  progress={handleProgressTrails(trailsData)}
                                />
                              </Progress>
                            </InfoWrapper>
                          </button>
                        </ChapterItem>
                      )}
                    </ul>
                  </ProgressList>
                )}
            </RightSideBarContent>
          )}
        </ContentWrapper>
      )}
    </>
  );
};

export default ChaptersAndProgress;
