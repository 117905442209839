import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface TagColorProps {
  color: string;
}

export const Container = styled.li`
  display: flex;
  width: 100%;
  margin-bottom: 2.38rem;
`;

export const JourneyCard = styled(Link)`
  pointer-events: none;
  overflow: visible;
  width: 100%;
  height: 20.5rem;

  display: flex;

  align-items: flex-start;

  border: 2px solid #4caf50;
  border-radius: 1.8rem;
  padding: 1.8rem;

  box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.1);

  position: relative;
  transition: all 0.3s;

  &:hover {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
    top: -1px;
    transform: translateY(-5px);
  }

  @media (max-width: 600px) {
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
`;

export const JourneyContent = styled.div`
  pointer-events: auto;

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  header {
    display: flex;
    margin-bottom: 1rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
    color: #1e88e5;
  }

  > p {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
    line-height: 1.22rem;
    min-height: 7rem;
  }

  @media (max-width: 600px) {
    header {
      display: none;
    }
  }
`;

export const Tag = styled.span<TagColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 0.5rem;
  text-align: center;
  margin-right: 0.67rem;
  border-radius: 2.78rem;
  background-color: ${props => props.color};
  color: #fff;
`;

export const JourneyDetails = styled.div`
  display: flex;
  margin-top: 1rem;

  > div {
    display: flex;
  }

  @media (max-width: 1024px) {
    width: 100%;
    justify-content: space-around;

    div {
      &:last-child {
        margin-left: 0;
      }
    }
  }

  @media (max-width: 425px) {
    align-items: center;
    > div {
      flex-direction: column;
    }
  }
`;

export const MetricItem = styled.div`
  display: flex;
  > p {
    svg {
    }
  }
  > span {
    margin-left: 1rem;
    display: flex;
    flex-direction: column;

    h2 {
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.56rem;
    }
    p {
      font-size: 0.67rem;
    }
  }

  &:last-child {
    margin-left: 5rem;
  }

  @media (max-width: 1024px) {
    &:last-child {
      margin-left: 0;
    }
  }

  @media (max-width: 375px) {
    > p {
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        width: 1.2rem;
      }
    }

    > span {
      margin-left: 0.5rem;

      h2 {
        font-family: 'RedHatDisplay-ExtraBold';
        font-size: 1rem;
      }
      p {
        font-size: 0.67rem;
      }
    }

    .secondary-button {
      padding: 0.5rem 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      line-height: 1.28rem;
      border-radius: 0.55rem;
      vertical-align: center;
    }
  }
`;

export const IllustrationWrapper = styled.div`
  pointer-events: auto;

  width: 38%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    max-width: 100%;
    max-height: 100%;
  }

  @media (max-width: 600px) {
    width: 0%;
  }
`;

export const BnccSection = styled.div`
  margin-left: 3.89rem;

  > div {
    min-height: 9rem;
    max-height: 11rem;

    h3 {
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.22rem;
    }

    > div {
      display: flex;
      flex-wrap: wrap;
      max-width: 25rem;

      max-height: 10rem;

      span {
        margin-top: 0.83rem;
        padding: 0.3rem;
        margin-right: 0.67rem;
        border-radius: 2.78rem;
        font-size: 0.78rem;
        color: #1e88e5;
        text-align: center;
        border: 1px solid #1e88e5;
      }
    }
  }
  > div:nth-child(2) {
    margin-top: 1.8rem;

    span {
      color: #f39519;
      border: 1px solid #f39519;
    }
  }
`;

export const DropdownContainer = styled.div`
  pointer-events: auto;

  cursor: pointer;
  display: flex;
  margin: 0px 0.5em 0px 1.8em;
  position: relative;
  width: auto;

  .secondary-button {
    ponter-events: auto;
    display: flex;
    padding: 0 1.6rem;
    height: 2.89rem;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #323232;
    line-height: 1.28rem;
    border-radius: 0.55rem;
    vertical-align: center;
    gap: 1rem;
  }

  // The 'nav' item corresponds to Dropdown Component
  > nav {
    pointer-events: auto;
    z-index: 10000000;
    background: #f2f2f2;
    padding: 2rem;
    border-radius: 1.8rem;
    position: fixed;
    top: 30%;
    left: 50%;
    width: 90%;

    box-shadow: 0px 20px 30px rgba(85, 85, 85, 0.25);
    opacity: 0;
    visibility: hidden;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;

    &.visible {
      opacity: 1;
      visibility: visible;
    }

    &.hidden {
      display: none;
    }
  }

  @media (max-width: 1024px) {
    > nav {
      width: 50%;

      > div {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-center: center;
      }
    }

    .secondary-button {
      gap: 0.8rem;
    }
  }

  @media (max-width: 480px) {
    > nav {
      width: 80%;
    }
  }

  @media (max-width: 375px) {
    .secondary-button {
      padding: 0.5rem 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1rem;
      line-height: 1.28rem;
      border-radius: 0.55rem;
      vertical-align: center;
    }
  }
`;

export const ShimmerEffect = styled.div`
  background-image: linear-gradient(
    -90deg,
    #f5f5f5 0%,
    #e2e2e2 50%,
    #f5f5f5 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &.white {
    background-image: linear-gradient(
      -90deg,
      #f5f5f5 0%,
      #e2e2e2 50%,
      #f5f5f5 100%
    );
  }
`;
