import React from 'react';
import {
  AccordionContent,
  AccordionHeader,
  ChapterItem,
  Fill,
  InfoBar,
} from './styles';
import { Progress } from '../ChapterItem/styles';
import { ChevronDownIcon } from '../../assets/svg/SVGComponents';

// import { Container } from './styles';

interface ChapterAccordionProps {
  title: string;
  progress: number;
  videoAmount: number;
  children: React.ReactNode;
  selected: boolean;
  isOpen: boolean;
  onToggle: () => void;
}

const ChapterAccordion: React.FC<ChapterAccordionProps> = ({
  title,
  progress,
  children,
  selected,
  isOpen,
  onToggle,
}) => {
  return (
    <ChapterItem selected={selected}>
      <AccordionHeader type="button" onClick={onToggle}>
        <InfoBar progress={progress}>
          <div>
            <h3>{title}</h3>
            <span>{progress}%</span>
          </div>

          <Progress>
            <Fill progress={progress} />
          </Progress>
        </InfoBar>

        <div className="chevron">
          <ChevronDownIcon
            width="2rem"
            stroke="#1e88e5"
            strokeWidth={3}
            className={isOpen ? 'open' : ''}
          />
          {/* <span>({videoAmount})</span> */}
        </div>
      </AccordionHeader>

      <AccordionContent className={isOpen ? '' : 'collapsed'}>
        {children}
      </AccordionContent>
    </ChapterItem>
  );
};

export default ChapterAccordion;
