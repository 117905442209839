import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const SoundMuteVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 74">
    <path
      fillRule="evenodd"
      d="M29.396 54.659H6a6 6 0 0 1-6-6v-24a6 6 0 0 1 6-6h24c.197 0 .392.01.584.028l12.617-10.32C47.12 5.163 53 7.95 53 13.011v48.295c0 5.061-5.88 7.849-9.799 4.644L29.396 54.66Z"
      clipRule="evenodd"
    />
    <path d="M55 49a12 12 0 0 0 0-24v4.73a7.27 7.27 0 1 1 0 14.54V49Z" />
    <path d="M55 58a21 21 0 1 0 0-42v5.519a15.481 15.481 0 0 1 0 30.962V58Z" />
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeWidth={9}
      d="m9.194 9.726 65.084 54.225"
    />
    <path
      strokeLinecap="round"
      strokeWidth={9}
      d="m7.22 10.048 65.085 54.225"
    />
  </svg>
);
export default SoundMuteVideoIcon;
