import React, { useCallback, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
  Background,
  Container,
  ResponsiveLine4Steps,
  Step,
  Steps,
} from './styles';
import Line3Steps, {
  ButtonPointsProps,
} from '../../assets/svg/SVGComponents/Line3Steps';
import { StepType } from '../../utils/enums';
import IntroductionBook from '../../assets/svg/SVGComponents/IntroductionBook';
import {
  CodeText,
  KeyboardIcon,
  Microchip,
  SimulationIcon,
} from '../../assets/svg/SVGComponents';
import LegoBricks from '../../assets/svg/SVGComponents/LegoBricks';
import IStepProps from '../../dtos/IStepsProps';

interface ProgressBarProps {
  total_steps: number;
  steps: ProgressBarStepsProps[];
  current_step: ProgressBarStepsProps;
  onStepClick: (step: ProgressBarStepsProps) => void;
}
interface ProgressBarStepsProps {
  step: IStepProps;
  progress: number;
  order: number;
  x?: number;
  y?: number;
}

const stepIconMap = {
  [StepType.INTRO]: IntroductionBook,
  [StepType.CODING]: KeyboardIcon,
  [StepType.CIRCUIT]: Microchip,
  [StepType.SIMULATOR]: SimulationIcon,
  [StepType.BUILDING]: LegoBricks,
  [StepType.BEHIND]: CodeText,
  [StepType.LEARN]: CodeText,
  [StepType.TEACHER]: CodeText,
  // Adicione outros tipos se necessário
};
const ProgressBarTutorial: React.FC<ProgressBarProps> = ({
  total_steps,
  steps,
  current_step,
  onStepClick,
}) => {
  const { t } = useTranslation();
  const [buttonPosition, setButtonPosition] = useState<ButtonPointsProps[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePointsCalculated = useCallback((points: ButtonPointsProps[]) => {
    setButtonPosition(points);
  }, []);

  const svgOriginalWidth = 579.46899; // ou a largura original do seu SVG de referência
  const scaleFactor = containerRef.current
    ? containerRef.current.clientWidth / svgOriginalWidth
    : 1;

  const StepPositionCalculated = useMemo((): ProgressBarStepsProps[] => {
    if (containerRef.current && buttonPosition.length > 0) {
      const stepsWithPosition = steps.map((step, index) => {
        return {
          ...step,
          x: buttonPosition[index]?.x,
          y: buttonPosition[index]?.y,
        };
      });
      return stepsWithPosition;
    }

    return [];
  }, [buttonPosition, steps]);

  return (
    <Container>
      <Background ref={containerRef}>
        {total_steps === 3 && (
          <Line3Steps
            onPointsCalculated={handlePointsCalculated}
            width="23.5rem"
            style={{ display: 'block' }}
          />
        )}
        {total_steps === 4 && (
          <ResponsiveLine4Steps
            onPointsCalculated={handlePointsCalculated}
            width="100%"
            style={{ maxWidth: '32.167rem', display: 'block' }}
          />
        )}
      </Background>

      <Steps steps={steps.length}>
        {StepPositionCalculated &&
          StepPositionCalculated.map((step, index) => {
            const IconComponent = stepIconMap[step.step.step_type] || null;
            return (
              <Step
                key={index}
                accomplished={index + 1 <= current_step.order}
                positionX={step.x}
                positionY={step.y}
              >
                <span>{t(`spans.step_label_${step.step.step_type}`)}</span>
                <div>
                  <p />
                  <button
                    type="button"
                    onClick={() => onStepClick && onStepClick(step)}
                  >
                    {IconComponent && (
                      <IconComponent
                        width="2rem"
                        strokeWidth={1.5}
                        fill="none"
                      />
                    )}
                  </button>
                </div>
              </Step>
            );
          })}
      </Steps>
    </Container>
  );
};

export default ProgressBarTutorial;
