import React, { useCallback, useEffect, useState } from 'react';
import {
  AppBar as NavBar,
  Tabs as TabsContainer,
  Tab,
} from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { ProjectsIcon } from '../../assets/svg/SVGComponents';

import IProjectProps from '../../dtos/IProjectProps';
import ISolutionProps from '../../dtos/ISolutionProps';
import IStudentProps from '../../dtos/IStudentProps';

import { MainContent, NavigationMenu, MainContentHeader } from './styles';
import TrailsAndProgress from './TrailsAndProgress';
import Tools from './Tools';
import TrailsAndProgressMobile from './TrailsAndProgressMobile';

interface IClassStats {
  active_students: IStudentProps[];
  for_review: number;
  approved: number;
  contested: number;
  class_score: number;
  class_max_score: number;
  solutions_amount: number;
}

export interface IGetTeacherProjectsList {
  project: IProjectProps;
  solutions: ISolutionProps[];
  class_stats: IClassStats;
}

const Tutorial: React.FC = () => {
  const navigate = useNavigate();
  const { active_tab } = useParams();
  const [selectedTab, setSelectedTab] = useState<number>();
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (active_tab === 'progress') setSelectedTab(0);
    if (active_tab === 'tools') setSelectedTab(1);
    if (active_tab === 'knowledge') setSelectedTab(2);
  }, [active_tab]);

  const handleSelectedTabChange = useCallback(
    (_event, tabIndex) => {
      setSelectedTab(tabIndex);
      if (tabIndex === 0) navigate('progress');
      if (tabIndex === 1) navigate('tools');
    },
    [navigate],
  );

  useEffect(() => {
    if (active_tab === 'library') setSelectedTab(0);
  }, [active_tab]);

  useEffect(() => {
    if (isTabletOrMobile || isPortrait) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isPortrait, isTabletOrMobile]);

  return (
    <MainContent>
      <MainContentHeader>
        <ProjectsIcon width="2rem" stroke="#FF9800" strokeWidth={2} />
        <h1>{t('h1.projects')}</h1>
      </MainContentHeader>

      <NavigationMenu>
        <NavBar position="relative" className="profile-navBar">
          <TabsContainer
            value={selectedTab}
            onChange={handleSelectedTabChange}
            className="profile-tabs-container"
            TabIndicatorProps={{
              style: {
                background: '#FF9800',
                height: '5px',
                borderRadius: '2.5px',
                marginBottom: '-3px',
                zIndex: 1101,
              },
            }}
          >
            <Tab label={t('tabs.progress')} className="profile-tab-item" />
            <Tab label={t('tabs.tools')} className="profile-tab-item" />
          </TabsContainer>
        </NavBar>
        {selectedTab === 0 &&
          (isMobile ? <TrailsAndProgressMobile /> : <TrailsAndProgress />)}
        {selectedTab === 1 && <Tools />}
      </NavigationMenu>
    </MainContent>
  );
};

export default Tutorial;
