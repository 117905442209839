import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
  JourneyRow,
  MainContent,
  JourneyContent,
  JourneyItem,
  JourneyDetails,
  IllustrationWrapper,
  JourneyCard,
  BnccSection,
  MainContentHeader,
  Tag,
  Title,
  SearchInput,
  TitleWrapper,
  ChapterCard,
  IconSection,
  ContentSection,
  TagChapter,
  ChapterGrid,
  SearchArea,
} from './styles';
import {
  ArrowBackIcon,
  ChapterIcon,
  CrossIcon,
  ProjectsIcon,
  SearchIcon,
} from '../../../assets/svg/SVGComponents';
import IRequestError from '../../../dtos/IRequestError';
import { useAuth } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import api from '../../../services/api';
import IChapterProps from '../../../dtos/IChapterProps';
import ITrailsProps from '../../../dtos/ITrailsProps';

interface ITagProps {
  title: string;
  color: string;
}

interface ISearchChapterProps extends IChapterProps {
  trailSlug: string;
}

const Trails: React.FC = () => {
  const { t } = useTranslation();
  const { hardware_slug } = useParams();
  const { isSessionExpired, user } = useAuth();
  const { addToast } = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [trailsData, setTrailsData] = useState<ITrailsProps[] | null>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [numberRows, setNumberRows] = useState(1);
  const [inputText, setInputText] = useState('');
  const [chapterVisual, setChapterVisual] = useState(false);
  const [chapterData, setChapterData] = useState<ISearchChapterProps[] | null>(
    null,
  );
  const [filterData, setFilterData] = useState<ISearchChapterProps[] | null>(
    null,
  );
  const hardwareData = user.hardwares.find(
    hardware => hardware.slug === hardware_slug,
  );

  useEffect(() => {
    if (chapterData) {
      setNumberRows(chapterData.length / 3);
    }
  }, [chapterData, setNumberRows]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get(`/trails/${hardwareData?.id}`)
      .then(response => {
        setTrailsData(response.data);
        setIsLoading(false);
      })
      .catch(async (err: IRequestError) => {
        setIsLoading(false);

        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops.',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, hardwareData?.id, isSessionExpired, t, user]);

  const handleTagsTrails = useCallback(
    (slug: string): ITagProps[] => {
      if (slug) {
        return t(`trails.${slug}.tags`, { returnObjects: true });
      }

      return [];
    },
    [t],
  );
  const handleAbilitiesTrails = useCallback(
    (slug: string): ITagProps[] => {
      if (slug) {
        return t(`trails.${slug}.abilities`, { returnObjects: true });
      }

      return [];
    },
    [t],
  );

  const handleSubjectsTrails = useCallback(
    (slug: string): ITagProps[] => {
      if (slug) {
        return t(`trails.${slug}.subjects`, { returnObjects: true });
      }

      return [];
    },
    [t],
  );

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);
  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputText(event.target.value);
      setChapterVisual(true);
    },
    [],
  );

  const clearInput = useCallback(() => {
    setInputText('');
  }, []);

  useEffect(() => {
    if (trailsData) {
      const reduceChapters = trailsData.reduce(
        (chapters: ISearchChapterProps[], trail) => {
          // Adicionando o slug da trilha a cada capítulo
          const trailChaptersWithSlug = trail.chapters.map(chapter => ({
            ...chapter,
            trailSlug: trail.slug, // Supondo que `trail` tem uma propriedade `slug`
          }));

          return chapters.concat(trailChaptersWithSlug);
        },
        [],
      );

      setChapterData(reduceChapters);
    }
  }, [trailsData]);

  useEffect(() => {
    if (chapterData) {
      if (inputText.length) {
        const searched = chapterData.filter(template =>
          template.title.toLowerCase().includes(inputText.toLocaleLowerCase()),
        );
        setFilterData(searched);
      } else {
        setChapterVisual(false);
        setFilterData(chapterData);
      }
    }
  }, [chapterData, inputText]);

  const handleButtonVisualChapter = useCallback(() => {
    setChapterVisual(!chapterVisual);
  }, [chapterVisual]);

  const handleLinkTutorial = useCallback(
    (chapterItem: ISearchChapterProps): string => {
      let link = '';
      if (chapterItem.type === 'activity') {
        link = `/projects/${chapterItem.trailSlug}/${chapterItem.id}/activity/${chapterItem.activities[0].slug}`;
      }
      if (chapterItem.type === 'lesson') {
        link = `/videos/${chapterItem.lessons[0].journey_slug}/playlists/${chapterItem.lessons[0].playlist_slug}/lessons/${chapterItem.lessons[0].slug}`;
      }

      return link;
    },
    [],
  );

  return (
    <MainContent>
      <MainContentHeader>
        <Link to={-1 as any}>
          <ArrowBackIcon
            width="1.22rem"
            stroke="#1E88E5"
            strokeWidth={3}
            fill="none"
          />
          {t('navlinks.back')}
        </Link>
        <TitleWrapper>
          <Title>
            <ProjectsIcon width="2rem" stroke="#FF9800" strokeWidth={2} />
            <h1>{t('h1.trails')}</h1>
          </Title>
          <SearchArea>
            <button type="button" onClick={handleButtonVisualChapter}>
              {!chapterVisual
                ? 'Visualização por projetos'
                : 'Visualização por trilhas'}
            </button>
            <SearchInput isFocused={isFocused}>
              <SearchIcon
                height="1.72rem"
                fill="none"
                stroke="#1e88e5"
                strokeWidth={2}
              />
              <input
                placeholder={t('placeholders.search_templates')}
                onChange={handleInputChange}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                value={inputText}
                type="text"
              />
              <button type="button" onClick={clearInput}>
                <CrossIcon height="0.6rem" fill="none" strokeWidth={1} />
              </button>
            </SearchInput>
          </SearchArea>
        </TitleWrapper>
      </MainContentHeader>
      {!chapterVisual && trailsData && (
        <JourneyRow>
          {trailsData.map(trail => (
            <JourneyItem key={trail.id}>
              <JourneyCard to={`/trails/${trail.slug}/chapters`}>
                <JourneyContent>
                  <header>
                    {handleTagsTrails(trail.slug).map(tag => (
                      <Tag key={tag.title} color={tag.color}>
                        {tag.title}
                      </Tag>
                    ))}
                  </header>
                  <h1>{t(`trails.${trail.slug}.title`)}</h1>
                  <p>{t(`trails.${trail.slug}.description`)}</p>
                  <JourneyDetails>
                    <div>
                      <p>
                        <ChapterIcon
                          width="2.2rem"
                          fill="none"
                          stroke="#1e88e5"
                          strokeWidth={2.5}
                        />
                      </p>
                      <span>
                        <h2>{trail.chapters.length}</h2>
                        <p>{t(`trails.${trail.slug}.chapters_title`)}</p>
                      </span>
                    </div>
                    <div>
                      <p>
                        <ProjectsIcon
                          width="2.2rem"
                          stroke="#1e88e5"
                          strokeWidth={2.5}
                        />
                      </p>
                      <span>
                        <h2>
                          {trail.chapters.reduce((sum, chapter) => {
                            return sum + chapter.activities.length;
                          }, 0)}
                        </h2>
                        <p>{t(`trails.${trail.slug}.activities_title`)}</p>
                      </span>
                    </div>
                  </JourneyDetails>
                </JourneyContent>
                <IllustrationWrapper>
                  <img src={trail.image_url} alt="img-trail" />
                </IllustrationWrapper>
              </JourneyCard>
              <BnccSection>
                <div>
                  <h3>{t('h3.bncc_abilities')}</h3>
                  <div>
                    {handleAbilitiesTrails(trail.slug).map(ability => (
                      <span>{ability}</span>
                    ))}
                  </div>
                </div>
                {/* precisa mudar de cor as divs */}
                <div>
                  <h3>{t('h3.bncc_subjects')}</h3>
                  <div>
                    {handleSubjectsTrails(trail.slug).map(ability => (
                      <span>{ability}</span>
                    ))}
                  </div>
                </div>
              </BnccSection>
            </JourneyItem>
          ))}
        </JourneyRow>
      )}
      {trailsData && filterData && !!chapterVisual && (
        <ChapterGrid numberRows={numberRows}>
          {filterData.map(chapter => (
            <ChapterCard to={handleLinkTutorial(chapter)}>
              <IconSection>
                <img src={chapter.icon_url} alt="illustration" />
              </IconSection>
              <ContentSection>
                <header>
                  {handleTagsTrails(chapter.trailSlug).map(tag => (
                    <TagChapter key={tag.title} color={tag.color}>
                      {tag.title}
                    </TagChapter>
                  ))}
                </header>
                <h1>{t(`chapters.${chapter.slug}.title`)}</h1>
                <p>{t(`chapters.${chapter.slug}.short_description`)}</p>
              </ContentSection>
            </ChapterCard>
          ))}
        </ChapterGrid>
      )}
    </MainContent>
  );
};

export default Trails;
