import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ChapterImageProps {
  image: string;
}

interface TagColorProps {
  color: string;
}
interface ProgressProps {
  progress: number;
}

interface InputProps {
  isFocused: boolean;
}

interface GridProps {
  numberRows: number;
}

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.38rem;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #e2e2e2;

  > a {
    margin-bottom: 2.38rem;
    display: flex;
    align-items: center;
    width: auto;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.1rem;
    opacity: 0.8;
    transition: opacity 0.3s;

    > svg {
      margin-right: 0.89rem;
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const WelcomeSection = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  margin-bottom: 1.94rem;

  button > a {
    margin-right: 4.78rem;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0.9rem 1.8rem;
    border-radius: 0.5rem;
    box-shadow: 0 3px 15px rgba(243, 149, 25, 0.3);
    background: #f39519;
    background-size: 200% auto;
    transition: 0.3s;

    svg {
      margin-right: 8px;
    }

    span {
      font-family: 'RedHatDisplay-Bold';
      color: #fff;
    }

    &:hover {
      background-position: right center;
    }

    @media (max-width: 1024px) {
      margin-right: 0;
      margin-top: 2rem;
    }
  }

  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

export const InfoSection = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1024px) {
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Profile = styled.div`
  display: flex;
  align-items: center;

  > span {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    letter-spacing: 0;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 2.24rem;
    line-height: 2.48rem;
    color: #1e88e5;
    text-decoration: none;

    > a b {
      font-family: 'RedHatDisplay-ExtraBold';
      font-weight: normal;
    }
  }

  @media (max-width: 768px) {
    > span {
      font-size: 1.8rem;
      line-height: 1.9rem;
    }
  }

  @media (max-width: 480px) {
    > span {
      font-size: 1.6rem;
      line-height: 1.8rem;
    }
  }
`;

export const ProgressionSection = styled.div`
  min-width: 10.5rem;
  display: flex;
  align-items: center;
  padding: 1.22rem;
  border: 1px solid #e2e2e2;
  border-radius: 1.67rem;
  background: #fff;
  margin-left: 5.556rem;

  > span {
    margin-right: 2rem;
    font-size: 1.2rem;
  }

  > p {
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 2.2rem;
  }

  @media (max-width: 768px) {
    margin-left: 0;

    > span {
      margin-right: 1.5rem;
      font-size: 1rem;
    }

    > p {
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.8rem;
    }
  }
  @media (max-width: 480px) {
    min-width: 3rem;
    margin-left: 0;
    padding: 0.8rem;
    border-radius: 1rem;
    > span {
      margin-right: 0.5em;
      font-size: 1rem;
    }

    > p {
      font-family: 'RedHatDisplay-ExtraBold';
      font-size: 1.4rem;
    }
  }
`;

export const AvatarWrapper = styled.div`
  cursor: pointer;

  min-width: 3rem;
  max-width: 6.5rem;

  min-height: 3rem;
  max-height: 6.5rem;
  padding: 0.3rem;
  border-radius: 50%;
  border: 4px solid #f39519;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2rem;
  transition: box-shadow 0.3s;

  &:hover {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.16);
  }

  @media (max-width: 480px) {
    min-width: 3rem;
    max-width: 5rem;
    margin-right: 1rem;
  }
`;

export const UserAvatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
  }

  .shimmer-avatar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const JourneyRow = styled.ul`
  display: flex;
  flex-direction: column;

  @media (max-width: 1024px) {
    align-items: center;
  }
`;

export const SearchArea = styled.div`
  display: flex;
  margin-left: auto;

  > button {
    color: #1e88e5;
    opacity: 0.8;
    text-decoration: underline;
    transition: all 0.3s;

    &:hover {
      opacity: 1;
    }
  }
`;

export const TagChapter = styled.span<TagColorProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem 0.5rem;
  text-align: center;
  margin-right: 0.47rem;
  border-radius: 2.78rem;
  background-color: ${props => props.color};
  font-size: 0.7rem;
  color: #fff;
`;

export const ChapterItem = styled.li`
  display: flex;

  margin-bottom: 2.38rem;
`;

export const IconWrapper = styled.div`
  width: 6rem;
  height: 6rem;

  > img {
    width: 70%;
  }
`;
export const ChapterContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justfy-content: center;
  margin-left: 1.2rem;

  header {
    display: flex;
    margin-top: 1.83rem;
    margin-bottom: 1rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.7rem;
    margin-bottom: 0.5rem;
    color: #1e88e5;
  }

  > p {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
    line-height: 1.22rem;
  }
`;

export const ChapterGrid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: ${props => `repeat(${props.numberRows}, 10rem)`};
  gap: 2.4rem;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`;

export const ChapterCard = styled(Link)`
  height: 100%;
  display: flex;
  padding: 1rem;
  align-items: center;
  border: 1px solid #4caf50;
  border-radius: 20px;
  box-shadow: 3px 6px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: all 0.3s;
  min-height: 8.25rem;

  &:hover {
    box-shadow: 3px 3px 15px rgba(0, 0, 0, 0.1);
    top: -1px;
    transform: translateY(-5px);
  }
`;

export const IconSection = styled.div`
  margin-left: 1.4rem;
  width: 5.56rem;
  height: 5.56rem;
  border: 2px solid #1e88e5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: 70%;
    height: 70%;
  }

  @media (max-width: 1024px) {
    margin-left: 0;
    width: 3rem;
    height: 3rem;
  }
`;

export const ContentSection = styled.div`
  margin-left: 1.28rem;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    color: #1e88e5;
  }

  p {
    font-family: 'RedHatDisplay-Regular';
    font-size: 0.9rem;
  }

  @media (max-width: 1024px) {
    h1 {
      font-size: 1.2rem;
    }
  }
`;

export const AbilityMap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.4rem;

  > h1 {
    font-family: RedHatDisplay-ExtraBold;
    font-size: 2rem;
    margin-bottom: 1.6rem;
  }
`;

export const ColumnWrapper = styled.div`
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(3, 1fr);

  > div {
    margin-bottom: 2rem;
    font-family: 'RedHatDisplay-Regular';
    display: flex;
    flex-direction: column;
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const InfoBar = styled.div<ProgressProps>`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  font-size: 0.89rem;

  h3 {
    font-family: 'RedHatDisplay-Regular';
    font-size: 1rem;
    margin-left: 1rem;
  }

  span {
    font-family: 'RedHatDisplay-Bold';
    font-size: 1.125rem;
    color: ${props => (props.progress > 0 ? '#1e88e5' : '#e2e2e2')};
  }

  @media (max-width: 375px) {
    h3 {
      margin-left: 0.4rem;
      font-size: 0.9rem;
    }
  }
`;

export const Progress = styled.span`
  height: 1rem;
  width: 100%;
  border: 2px solid #1e88e5;
  background-color: #e2e2e2;
  border-radius: 5.56rem;
`;

export const Trails = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .shimmer-card {
    width: 56.2rem;
    height: 20.5rem;
    border-radius: 1.8rem;
  }
`;

export const HeaderTrails = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2.8rem;
  margin-bottom: 3.8rem;

  > h1 {
    font-family: 'RedHatDisplay-ExtraBold';
  }

  @media (max-width: 1024px) {
    margin-top: 1rem;
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SearchSectionWrapped = styled.div`
  display: flex;

  @media (max-width: 1024px) {
    margin-top: 1.65rem;
  }
`;

export const SearchInput = styled.div<InputProps>`
  margin-left: 1.2rem;
  display: flex;
  align-items: center;
  border: 1px solid #e2e2e2;
  background: #fff;
  border-radius: 0.8rem;
  padding: 0.53rem 0.58rem;
  width: 23.22rem;
  height: 2.72rem;
  border: 1px solid ${props => (props.isFocused ? '#EC775D' : '#e2e2e2')};

  input {
    font-size: 1rem;
    background: transparent;
    flex: 1;
    border: 0;
    margin-left: 0.67rem;

    &::placeholder {
      margin: 0;
      font-size: 1rem;
    }
  }

  button {
    margin-right: 0.3rem;

    > svg {
      stroke: ${props => (props.isFocused ? '#EC775D' : '#e2e2e2')};
    }
  }

  @media (max-width: 1024px) {
    width: 18rem;
  }

  @media (max-width: 375px) {
    width: 13rem;
  }
`;

export const Fill = styled.div<ProgressProps>`
  width: ${props => props.progress}%;
  height: 100%;
  background-color: #1e88e5;
  border-radius: inherit;
  text-align: right;
`;

export const ChapterWrapper = styled.div`
  margin-top: 2.61rem;
  margin-left: 1.5rem;
  width: 100%;
`;

export const TrailTitle = styled.div`
  margin-bottom: 5rem;
  display: flex;

  h2 {
    font-family: 'RedHatDisplay-Bold';
    margin-left: 1.33rem;
    color: #1e88e5;
  }
`;

export const ChaptersView = styled.div`
  width: 100%;
`;

export const ImageChapter = styled.div<ChapterImageProps>`
  background: url('${props => props.image}') no-repeat center;
  background-size: cover;
`;

export const ShimmerEffect = styled.div`
  background-image: linear-gradient(
    -90deg,
    #f5f5f5 0%,
    #e2e2e2 50%,
    #f5f5f5 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }

  &.white {
    background-image: linear-gradient(
      -90deg,
      #f5f5f5 0%,
      #e2e2e2 50%,
      #f5f5f5 100%
    );
  }
`;
