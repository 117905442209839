import { createGlobalStyle } from 'styled-components';
import MarkProHeavy from '../assets/fonts/MarkPro/MARKPROHEAVY.woff';
import MarkProBlack from '../assets/fonts/MarkPro/MARKPROBLACK.woff';
import MarkProMedium from '../assets/fonts/MarkPro/MARKPROMEDIUM.woff';
import RedHatDisplayBold from '../assets/fonts/RedhatDisplay/RedHatDisplay-Bold.woff';
import RedHatDisplayRegular from '../assets/fonts/RedhatDisplay/RedHatDisplay-Regular.woff';
import RedHatDisplayExtraBold from '../assets/fonts/RedhatDisplay/RedHatDisplay-Black.woff';
import RedHatDisplayMedium from '../assets/fonts/RedhatDisplay/RedHatDisplay-Medium.woff';

export default createGlobalStyle`

    @font-face{
      font-family: 'RedHatDisplay-Regular';
      font-style: normal;
      font-weight: normal;
      src: local('RedHatDisplay-Regular'), url('${RedHatDisplayRegular}') format('woff');
    }

    @font-face{
      font-family: 'RedHatDisplay-Medium';
      font-style: normal;
      font-weight: normal;
      src: local('RedHatDisplay-Medium'), url('${RedHatDisplayMedium}') format('woff');
    }

    @font-face{
      font-family: 'RedHatDisplay-Bold';
      font-style: normal;
      font-weight: normal;
      src: local('RedHatDisplay-Bold'), url('${RedHatDisplayBold}') format('woff');
    }

    @font-face{
      font-family: 'RedHatDisplay-ExtraBold';
      font-style: normal;
      font-weight: normal;
      src: local('RedHatDisplay-ExtraBold'), url('${RedHatDisplayExtraBold}') format('woff');
    }

    @font-face {
    font-family: 'MarkPro';
    font-style: normal;
    font-weight: normal;
    src: local('MarkPro'), url('${RedHatDisplayRegular}') format('woff');
    }


    @font-face {
    font-family: 'MarkPro-Medium';
    font-style: normal;
    font-weight: normal;
    src: local('MarkPro-Medium'), url('${MarkProMedium}') format('woff');
    }


    @font-face {
    font-family: 'MarkPro-Bold';
    font-style: normal;
    font-weight: normal;
    src: local('MarkPro-Bold'), url('${RedHatDisplayBold}') format('woff');
    }

    @font-face {
    font-family: 'MarkPro-Heavy';
    font-style: normal;
    font-weight: normal;
    src: local('MarkPro-Heavy'), url('${MarkProHeavy}') format('woff');
    }

    @font-face {
    font-family: 'MarkPro-Black';
    font-style: normal;
    font-weight: normal;
    src: local('MarkPro-Black'), url('${MarkProBlack}') format('woff');
    }

  * {
    margin: 0;
    outline: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background: #FFFEFC;
    color: #323232;
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'RedHatDisplay-Regular';
    font-size: var(--font-size);
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }

  button {
    cursor: pointer;
    border: 0;
    background: none;
  }

  li {
    list-style: none;
  }

  a {
    text-decoration: none;
    color: #323232;
  }

  b {
    font-weight: normal;
  }

  svg {
    visibility: visible;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
  }

  .react-select__control.react-select__control--is-focused.react-select__control--is-focused {
    border: 1px solid rgba(236, 119, 93, 1);
    outline: 0;
    box-shadow: none;
  }

  .MuiTab-root {
    min-width: unset !important;
  }

  :root, #modal-root {

    @media (min-width: 1921px) , (min-height: 1081px) {
      --sidebar-width: 9.6vw;
      --header-height: 4.8vw;
      --videobar-width: 27.4vw;
      --font-size: 0.94vw;
    }

    @media (max-width: 1920px) , (max-height: 1080px) {
      --sidebar-width: 9.6vw;
      --header-height: 4.8vw;
      --videobar-width: 27.4vw;
      --font-size: 18px;
    }

    @media (max-width: 1720px), (max-height: 900px) {
      --font-size: 17px;
      --sidebar-width: 10vw;
    }

    @media (max-width: 1620px), (max-height: 860px) {
      --font-size: 16.5px;
    }


    @media (max-width: 1560px), (max-height: 840px) {
      --font-size: 16px;
    }

    @media (max-width: 1510px), (max-height: 800px) {
      --font-size: 15.5px;
    }

    @media (max-width: 1456px), (max-height: 780px) {
      --font-size: 14px;
    }

    @media (max-width: 1410px), (max-height: 740px) {
      --font-size: 14px;
    }

    @media (max-width: 1380px), (max-height: 720px) {
      --font-size: 14px;
    }

    @media (max-width: 1300px), (max-height: 680px) {
      --font-size: 13.5px;
    }

    @media (max-width: 1280px), (max-height: 624px) {
      --font-size: 12px;
    }

    @media (max-width: 1166px), (max-height: 560px) {
      --font-size: 11px;
      --videobar-width: 0vw;
    }

      /* Configurações para tablets */
  @media (max-width: 1024px) {
    --sidebar-width: 0vw;
    --videobar-width: 0vw;
    --font-size: 16px;
  }

  @media (max-width: 768px) {
    --sidebar-width: 0vw;
    --font-size: 14px;
  }

      /* Configurações para celulares */
  @media (max-width: 600px) {
    --sidebar-width: 0vw;
    --font-size: 12px;
  }

  @media (max-width: 320px) {
    --sidebar-width: 0vw;
    --font-size: 10px;
  }

    font-size: var(--font-size);
  }
`;
