import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { VideosIcon } from '../../../assets/svg/SVGComponents';

import { MainContent, MainContentHeader } from './styles';
import PlaylistAndProgress from '../PlaylistAndProgress';
import PlaylistAndProgressMobile from '../PlaylistAndProgressMobile';

const Journeys: React.FC = () => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (isTabletOrMobile || isPortrait) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isPortrait, isTabletOrMobile]);

  return (
    <MainContent>
      <MainContentHeader>
        <VideosIcon width="2rem" stroke="#FF9800" strokeWidth={2} />
        <h1>{t('h1.learning_journeys')}</h1>
      </MainContentHeader>
      {isMobile ? <PlaylistAndProgressMobile /> : <PlaylistAndProgress />}
    </MainContent>
  );
};
export default Journeys;
