import * as React from 'react';

const FlipUpIcon: React.FC<
  JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
> = (props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      clipPath="url(#a)"
    >
      <path d="M21 4a2 2 0 0 0-2-2M5 2a2 2 0 0 0-2 2M12.833 7h-2.5v2.5" />
      <path d="M10.333 7.39c1.954.77 3.333 2.63 3.333 4.807 0 2.176-1.38 4.033-3.333 4.802" />
      <path d="M7 12H5a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2h-2M10.33 2h-2M15.67 2h-2M3 7.33v2M21 7.33v2" />
    </g>
    <defs>
      <clipPath id="a">
        <path d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default FlipUpIcon;
