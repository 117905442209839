import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link } from 'react-router-dom';

import {
  BellIcon,
  ChevronDownIcon,
  LogoutIcon,
  UserCircleIcon,
} from '../../assets/svg/SVGComponents';

import { useAuth } from '../../hooks/auth';

import Dropdown, { DropdownHandles } from '../Dropdown';

import {
  Container,
  SearchBar,
  Content,
  Profile,
  LanguageButtons,
  DropdownContainer,
  UserAvatar,
} from './styles';

import avatar_api_letters from '../../services/avatarLetters';

const Header: React.FC = () => {
  const userDropdownRef = useRef<DropdownHandles>(null);
  const refDropdown = useRef<HTMLDivElement>(null);
  const { user, signOut } = useAuth();
  const { t } = useTranslation();

  const handleDropdownClick = useCallback(() => {
    userDropdownRef.current?.toggleDropdown();
  }, []);

  const handleLogoutClick = useCallback(() => {
    userDropdownRef.current?.toggleDropdown();
    signOut();
  }, [signOut]);

  const handleLanguageChange = useCallback((lang_code: string) => {
    i18next.changeLanguage(lang_code);
  }, []);

  const clickListener = useCallback((e: MouseEvent) => {
    if (!refDropdown || !refDropdown?.current?.contains(e.target as Node)) {
      userDropdownRef.current?.closeDropdown();
    }
  }, []);

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener('mousedown', clickListener);

    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [clickListener, refDropdown]);

  return (
    <Container>
      <Content>
        <SearchBar />

        <Profile>
          <LanguageButtons>
            <button
              type="button"
              className={i18next.language === 'pt-BR' ? 'active-language' : ''}
              onClick={() => handleLanguageChange('pt-BR')}
            >
              PT
            </button>
            |
            <button
              type="button"
              className={i18next.language === 'en' ? 'active-language' : ''}
              onClick={() => handleLanguageChange('en')}
            >
              EN
            </button>
          </LanguageButtons>

          <BellIcon strokeWidth={2} stroke="#323232" width="1.8rem" />

          <DropdownContainer ref={refDropdown}>
            <button type="button" onClick={handleDropdownClick}>
              <UserAvatar
                avatarSrc={user.avatar_url || avatar_api_letters(user.name)}
              />
              <ChevronDownIcon strokeWidth={3} stroke="#323232" width="0.8em" />
            </button>

            <Dropdown ref={userDropdownRef}>
              <ul>
                <li>
                  <Link to="/profile" onClick={handleDropdownClick}>
                    <UserCircleIcon
                      width="1.44rem"
                      stroke="#323232"
                      strokeWidth={1.5}
                      fill="none"
                    />
                    {t('header.my_profile')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    style={{ color: '#FF9800' }}
                    onClick={handleLogoutClick}
                  >
                    <LogoutIcon
                      width="1.44rem"
                      stroke="#FF9800"
                      strokeWidth={1.5}
                      fill="none"
                    />
                    {t('header.signout')}
                  </Link>
                </li>
              </ul>
            </Dropdown>
          </DropdownContainer>
        </Profile>
      </Content>
    </Container>
  );
};

export default Header;
