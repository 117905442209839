import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Link, useLocation } from 'react-router-dom';

import {
  BlogIcon,
  ChevronDownIcon,
  HelpIcon,
  HomeIcon,
  LogoRobotIcon,
  LogoutIcon,
  MenuBurguerIcon,
  ProjectsIcon,
  SimulationIcon,
  StoreIcon,
  ToolsIcon,
  UserCircleIcon,
  VideosIcon,
} from '../../assets/svg/SVGComponents';

import { useAuth } from '../../hooks/auth';

import Dropdown, { DropdownHandles } from '../Dropdown';

import {
  Container,
  Content,
  Profile,
  LanguageButtons,
  DropdownContainer,
  UserAvatar,
  Menu,
  NavLinkElm,
  ExternalLink,
  LogoArea,
} from './styles';

import avatar_api_letters from '../../services/avatarLetters';

const HeaderMobile: React.FC = () => {
  const { pathname } = useLocation();

  const userDropdownRef = useRef<DropdownHandles>(null);
  const refDropdown = useRef<HTMLDivElement>(null);

  const menuDropdownRef = useRef<DropdownHandles>(null);
  const refMenuDropdown = useRef<HTMLDivElement>(null);

  const { user, signOut } = useAuth();
  const { t } = useTranslation();

  const handleMenuDropdownClick = useCallback(() => {
    menuDropdownRef.current?.toggleDropdown();
  }, []);

  const handleDropdownClick = useCallback(() => {
    userDropdownRef.current?.toggleDropdown();
  }, []);

  const handleDropdownMenuClick = useCallback(() => {
    menuDropdownRef.current?.toggleDropdown();
  }, []);

  const handleLogoutClick = useCallback(() => {
    userDropdownRef.current?.toggleDropdown();
    signOut();
  }, [signOut]);

  const handleLanguageChange = useCallback((lang_code: string) => {
    i18next.changeLanguage(lang_code);
  }, []);

  const clickListener = useCallback((e: MouseEvent) => {
    if (!refDropdown || !refDropdown?.current?.contains(e.target as Node)) {
      userDropdownRef.current?.closeDropdown();
    }
  }, []);

  useEffect(() => {
    // Attach the listeners on component mount.
    document.addEventListener('mousedown', clickListener);

    // Detach the listeners on component unmount.
    return () => {
      document.removeEventListener('mousedown', clickListener);
    };
  }, [clickListener, refDropdown]);
  return (
    <Container>
      <Content>
        <Menu ref={refMenuDropdown}>
          <button type="button" onClick={() => handleDropdownMenuClick()}>
            <MenuBurguerIcon strokeWidth={5} stroke="#1E88E5" width="1.67rem" />
          </button>

          <Dropdown ref={menuDropdownRef}>
            <ul>
              <li>
                <NavLinkElm
                  to="/home"
                  onClick={handleMenuDropdownClick}
                  className={
                    pathname.startsWith('/home') ||
                    pathname.startsWith('/profile')
                      ? 'navlink-active'
                      : ''
                  }
                >
                  <HomeIcon
                    strokeWidth={2}
                    stroke="rgb(255,255,255)"
                    width="2rem"
                  />
                  <span>{t('left_sidebar.home')}</span>
                </NavLinkElm>
              </li>
              <li>
                <NavLinkElm
                  to="/hardwares"
                  onClick={handleMenuDropdownClick}
                  className={
                    pathname.startsWith('/hardwares') ? 'navlink-active' : ''
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  <ToolsIcon
                    strokeWidth={2.5}
                    stroke="rgb(255, 255, 255)"
                    width="1.9rem"
                  />
                  <span>{t('left_sidebar.hardware')}</span>
                </NavLinkElm>
              </li>
              <li>
                <NavLinkElm
                  to="/videos/progress"
                  onClick={handleMenuDropdownClick}
                  className={
                    pathname.startsWith('/videos') ? 'navlink-active' : ''
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                >
                  <VideosIcon
                    strokeWidth={2}
                    stroke="rgb(255, 255, 255)"
                    width="2rem"
                  />
                  <span>{t('left_sidebar.videos')}</span>
                </NavLinkElm>
              </li>
              <li>
                <NavLinkElm
                  to="/projects/progress"
                  onClick={handleMenuDropdownClick}
                  className={
                    pathname.startsWith('/projects') ||
                    pathname.startsWith('/trails')
                      ? 'navlink-active'
                      : ''
                    // eslint-disable-next-line react/jsx-curly-newline
                  }
                  end
                >
                  <ProjectsIcon
                    strokeWidth={2}
                    stroke="rgb(255, 255, 255)"
                    width="2rem"
                  />
                  <span>{t('left_sidebar.projects')}</span>
                </NavLinkElm>
              </li>
              <li>
                <ExternalLink
                  href="https://loja.fuzzymakers.com.br/"
                  onClick={handleMenuDropdownClick}
                  rel="noreferrer"
                  target="_blank"
                >
                  <StoreIcon
                    strokeWidth={2}
                    stroke="rgb(255, 255, 255)"
                    width="2rem"
                  />
                  <span>{t('left_sidebar.store')}</span>
                </ExternalLink>
              </li>
              <li>
                <ExternalLink
                  href="https://fuzzymakers.com.br/blog/"
                  onClick={handleMenuDropdownClick}
                  rel="noreferrer"
                  target="_blank"
                >
                  <BlogIcon
                    strokeWidth={2}
                    stroke="rgb(255, 255, 255)"
                    width="2rem"
                  />
                  <span>{t('left_sidebar.blog')}</span>
                </ExternalLink>
              </li>
              <li>
                <ExternalLink
                  href="https://play.fuzzymakers.com"
                  onClick={handleMenuDropdownClick}
                  rel="noreferrer"
                  target="_blank"
                >
                  <SimulationIcon
                    strokeWidth={1.5}
                    stroke="rgb(255, 255, 255)"
                    width="2rem"
                  />
                  <span>{t('left_sidebar.simulator')}</span>
                </ExternalLink>
              </li>
              <li>
                <ExternalLink
                  href="https://wa.me/message/ICA65FNRIPESD1"
                  onClick={handleMenuDropdownClick}
                  rel="noreferrer"
                  target="_blank"
                >
                  <HelpIcon
                    strokeWidth={2}
                    stroke="rgb(255, 255, 255)"
                    width="2rem"
                  />
                  <span>{t('left_sidebar.help')}</span>
                </ExternalLink>
              </li>
            </ul>
          </Dropdown>
        </Menu>

        <LogoArea>
          <LogoRobotIcon width="2.21rem" fill="#1e88e5" />
        </LogoArea>

        <Profile>
          <LanguageButtons>
            <button
              type="button"
              className={i18next.language === 'pt-BR' ? 'active-language' : ''}
              onClick={() => handleLanguageChange('pt-BR')}
            >
              PT
            </button>
            |
            <button
              type="button"
              className={i18next.language === 'en' ? 'active-language' : ''}
              onClick={() => handleLanguageChange('en')}
            >
              EN
            </button>
          </LanguageButtons>

          <DropdownContainer ref={refDropdown}>
            <button type="button" onClick={handleDropdownClick}>
              <UserAvatar
                avatarSrc={user.avatar_url || avatar_api_letters(user.name)}
              />
              <ChevronDownIcon strokeWidth={3} stroke="#323232" width="0.8em" />
            </button>

            <Dropdown ref={userDropdownRef}>
              <ul>
                <li>
                  <Link to="/profile" onClick={handleDropdownClick}>
                    <UserCircleIcon
                      width="1.44rem"
                      stroke="#323232"
                      strokeWidth={1.5}
                      fill="none"
                    />
                    {t('header.my_profile')}
                  </Link>
                </li>
                <li>
                  <Link
                    to="/"
                    style={{ color: '#FF9800' }}
                    onClick={handleLogoutClick}
                  >
                    <LogoutIcon
                      width="1.44rem"
                      stroke="#FF9800"
                      strokeWidth={1.5}
                      fill="none"
                    />
                    {t('header.signout')}
                  </Link>
                </li>
              </ul>
            </Dropdown>
          </DropdownContainer>
        </Profile>
      </Content>
    </Container>
  );
};

export default HeaderMobile;
