import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface HardwareImageProps {
  background: string;
}

export const ToolsGrid = styled.div`
  max-width: 100%;
  height: 80vh;
  margin-top: 3.66rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(6, 1fr);
  column-gap: 2rem;
  row-gap: 2rem;
  grid-template-areas:
    'item1 item3'
    'item1 item3'
    'item1 item4'
    'item2 item4'
    'item2 item5'
    'item2 item5';

  .item-1 {
    grid-area: item1;
  }
  .item-2 {
    grid-area: item2;
  }
  .item-3 {
    grid-area: item3;
  }
  .item-4 {
    grid-area: item4;
  }
  .item-5 {
    grid-area: item5;
  }

  @media (max-width: 1224px) {
    order: 3; /* Define a ordem do Dashboard como 3 (último) */
    padding: 1.5rem; /* Reduz o padding */
  }

  @media (max-width: 768px) {
    /* Ajustar altura automática no mobile */
    height: auto;
    margin-top: 2rem;

    /* Agora só 1 coluna */
    grid-template-columns: 1fr;
    /* 5 linhas (uma para cada item) */
    grid-template-rows: repeat(5, 1fr);

    /* Zerar o espaçamento de coluna, manter/ajustar row-gap */
    column-gap: 2rem;
    row-gap: 2rem;

    /* Redefine a sequência de aparecimento dos itens */
    grid-template-areas:
      'item1'
      'item2'
      'item3'
      'item4'
      'item5';
  }
`;

export const ToolCard = styled(Link)<HardwareImageProps>`
  background: ${props => `url("${props.background}") no-repeat center`};
  width: 100%;
  min-height: 13.93rem;
  border-radius: 1.67rem;
  cursor: pointer;
  position: relative;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.102);
  background-size: cover;
  cursor: pointer;
  overflow: visible;
  transition: all 0.3s;

  &:hover {
    box-shadow: 4px 8px 15px 8px rgba(0, 0, 0, 0.16);
    transform: translateY(-5px);
  }

  /* display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center; */

  > span {
    position: absolute;
    top: 50%; /* Deslocamento vertical em relação ao topo */
    left: 50%; /* Deslocamento horizontal em relação à esquerda */
    transform: translate(-50%, -50%);
    white-space: nowrap;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 3.33rem;
    color: #fff;
    line-height: 3.33rem;
    text-align: center;
  }

  > p {
    position: absolute;
    bottom: 1.38rem;
    right: 1.94rem;
  }
`;
