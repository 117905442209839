import styled from 'styled-components';

export const Container = styled.button`
  background: #fff;
  color: #323232;
  font-family: 'RedHatDisplay-Medium';
  border: 1px solid #e2e2e2;
  outline: 0;

  transition: 0.3s;

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #4caf50;
    color: #4caf50;
  }
`;
