import React, { memo, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Header from '../../components/Header';
import LeftSideBar from '../../components/LeftSideBar';

import { Container, Content } from './styles';
import HeaderMobile from '../../components/HeaderMobile';

const MainLayout: React.FC = ({ children }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (isTabletOrMobile || isPortrait) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isPortrait, isTabletOrMobile]);

  return (
    <Container>
      {!isMobile && <LeftSideBar />}

      <Content>
        {isMobile ? <HeaderMobile /> : <Header />}
        {children}
      </Content>
    </Container>
  );
};

export default memo(MainLayout);
