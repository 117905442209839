import React from 'react';
import IActivityProps from '../../../../dtos/IActivitiesProps';
import IStepProps from '../../../../dtos/IStepsProps';
import { Container } from './styles';
import VideoPlayer from '../../../../components/VideoPlayer';

interface IComponentProps {
  currentActivity: IActivityProps;
  currentStep: IStepProps;
}

const VideoComponent: React.FC<IComponentProps> = ({ currentStep: step }) => {
  return (
    <Container>
      <VideoPlayer url={step.resource_url} />
    </Container>
  );
};

export default VideoComponent;
