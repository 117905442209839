import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import {
  BnccSection,
  Container,
  DropdownContainer,
  IllustrationWrapper,
  JourneyCard,
  JourneyContent,
  JourneyDetails,
  MetricItem,
  Tag,
} from './stytes';
import {
  ChapterIcon,
  CrossIcon,
  FlipUpIcon,
  ProjectsIcon,
} from '../../assets/svg/SVGComponents';
import SecondaryButton from '../Buttons/SecondaryButton';
import IChapterProps from '../../dtos/IChapterProps';
import Dropdown, { DropdownHandles } from '../Dropdown';

// import { Container } from './styles';

interface ITagProps {
  title: string;
  color: string;
}

interface JourneyProps {
  id: string;
  slug: string;
  chapters: IChapterProps[];
  image_url: string;
}

const BNCCArea: React.FC<{ slug: string }> = ({ slug }) => {
  const { t } = useTranslation();

  const handleSubjectsTrails = useCallback(
    (slugTrail: string): ITagProps[] => {
      if (slugTrail) {
        return t(`trails.${slugTrail}.subjects`, { returnObjects: true });
      }

      return [];
    },
    [t],
  );

  const handleAbilitiesTrails = useCallback(
    (slugTrail: string): ITagProps[] => {
      if (slugTrail) {
        return t(`trails.${slugTrail}.abilities`, { returnObjects: true });
      }

      return [];
    },
    [t],
  );

  return (
    <BnccSection>
      <div>
        <h3>{t('h3.bncc_abilities')}</h3>
        <div>
          {handleAbilitiesTrails(slug).map((ability, index) => (
            <span key={index}>{ability}</span>
          ))}
        </div>
      </div>
      {/* precisa mudar de cor as divs */}
      <div>
        <h3>{t('h3.bncc_subjects')}</h3>
        <div>
          {handleSubjectsTrails(slug).map(ability => (
            <span>{ability}</span>
          ))}
        </div>
      </div>
    </BnccSection>
  );
};

const JourneyItem: React.FC<JourneyProps> = ({
  id,
  slug,
  chapters,
  image_url,
}) => {
  const { t } = useTranslation();

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });

  const [isMobile, setIsMobile] = useState(false);

  const userDropdownRef = useRef<DropdownHandles>(null);
  const refDropdown = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTabletOrMobile || isPortrait) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isPortrait, isTabletOrMobile]);

  const handleDropdownClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    userDropdownRef.current?.toggleDropdown();
  }, []);

  const handleTagsTrails = useCallback(
    (slugTrail: string): ITagProps[] => {
      let tags: ITagProps[] = [];
      if (slugTrail) {
        tags = t(`trails.${slugTrail}.tags`, { returnObjects: true });
      }

      return tags;
    },
    [t],
  );

  const handleClose = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();

    userDropdownRef.current?.closeDropdown();
  }, []);

  return (
    <Container key={id}>
      <JourneyCard to={`/trails/${slug}/chapters`}>
        <JourneyContent>
          <header>
            {handleTagsTrails(slug).map(tag => (
              <Tag key={tag.title} color={tag.color}>
                {tag.title}
              </Tag>
            ))}
          </header>
          <h1>{t(`trails.${slug}.title`)}</h1>
          <p>{t(`trails.${slug}.description`)}</p>
          <JourneyDetails>
            <div>
              <MetricItem>
                <p>
                  <ChapterIcon
                    width="2.2rem"
                    fill="none"
                    stroke="#1e88e5"
                    strokeWidth={2.5}
                  />
                </p>
                <span>
                  <h2>{chapters.length}</h2>
                  <p>{t(`trails.${slug}.chapters_title`)}</p>
                </span>
              </MetricItem>
              <MetricItem>
                <p>
                  <ProjectsIcon
                    width="2.2rem"
                    stroke="#1e88e5"
                    strokeWidth={2.5}
                  />
                </p>
                <span>
                  <h2>
                    {chapters.reduce((sum, chapter) => {
                      return sum + chapter.activities.length;
                    }, 0)}
                  </h2>
                  <p>{t(`trails.${slug}.activities_title`)}</p>
                </span>
              </MetricItem>
            </div>
            {isMobile && (
              <DropdownContainer ref={refDropdown}>
                <SecondaryButton onClick={handleDropdownClick}>
                  <FlipUpIcon
                    stroke="#323232"
                    strokeWidth={3}
                    width="1.2rem"
                    height="1.2rem"
                  />
                  {t('buttons.show_abilities')}
                </SecondaryButton>
                <Dropdown ref={userDropdownRef}>
                  <CrossIcon
                    onClick={handleClose}
                    width="1.22rem"
                    stroke="#323650"
                    strokeWidth={1.5}
                  />
                  <BNCCArea slug={slug} />
                </Dropdown>
              </DropdownContainer>
            )}
          </JourneyDetails>
        </JourneyContent>
        <IllustrationWrapper>
          <img src={image_url} alt="img-trail" />
        </IllustrationWrapper>
      </JourneyCard>
      {!isMobile && <BNCCArea slug={slug} />}
    </Container>
  );
};

export default JourneyItem;
