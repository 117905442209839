import { lighten } from 'polished';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface HardwareImageProps {
  background: string;
}

export const MainContent = styled.div`
  width: 100%;
  margin-bottom: 4rem;
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;

  border-bottom: 1px solid #e2e2e2;
  > a {
    display: flex;
    align-items: center;
    width: auto;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.1rem;
    opacity: 0.8;
    transition: opacity 0.3s;

    > svg {
      margin-right: 0.89rem;
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const HeaderMainContent = styled.div`
  display: flex;
  align-tems: center;
  justify-content: space-between;
  margin-top: 1.58rem;

  > .primary-button {
    margin-right: 10.44rem;
    width: 14.67rem;
    height: 3.56rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #fff;
    line-height: 1.28rem;
    padding: 0.77rem 1rem;
    border-radius: 0.55rem;
    min-width: 10.57rem;
    min-height: 2.82rem;
    box-shadow: 0px 3px 6px rgba(255, 159, 0, 0.3);
  }
`;

export const TitleWrapper = styled.header`
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-bottom: 3.44rem;

  > svg {
    margin-right: 0.88rem;
  }

  h1 {
    text-align: center;
    font-family: 'RedHatDisplay-Extrabold';
    color: #1e88e5;
    font-size: 2rem;
    line-height: 2.55rem;
    margin-right: 0.88rem;
  }

  > div > svg {
    display: block;
    margin: auto;
    position: relative;
    cursor: pointer;
    transition: stroke 0.3s;
  }

  > div > svg {
    stroke: ${lighten(0.16, '#63646A')};

    &:hover {
      stroke: #63646a;
    }
  }

  p {
    font-family: 'MarkPro';
    font-size: 1rem;
    line-height: 1.44rem;
    margin-left: 1.88rem;
  }
`;
