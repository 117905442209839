import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const PlayVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 36">
    <path d="M29.532 12.934c3.994 2.32 3.978 8.094-.028 10.392L9.974 34.532c-4.006 2.299-8.999-.601-8.986-5.22l.06-22.517c.013-4.619 5.02-7.492 9.014-5.172l19.47 11.31Z" />
  </svg>
);
export default PlayVideoIcon;
