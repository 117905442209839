import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const FowardVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 51">
    <path d="M41.08 19.914c3.995 2.32 3.979 8.093-.027 10.392l-19.53 11.206c-4.006 2.299-8.999-.601-8.986-5.22l.06-22.517c.012-4.619 5.02-7.492 9.014-5.172l19.47 11.31Z" />
    <path d="M66.08 19.914c3.995 2.32 3.979 8.093-.027 10.392l-19.53 11.206c-4.007 2.299-8.999-.601-8.986-5.22l.06-22.517c.012-4.619 5.02-7.492 9.014-5.172l19.47 11.31Z" />
    <rect width={15} height={38} x={66} y={6} rx={6} />
  </svg>
);
export default FowardVideoIcon;
