import React, { useCallback, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  ContentWrapper,
  IconContent,
  MainContent,
  PlaylistCard,
  TimelineItem,
  ChapterInfos,
  IconContentActivity,
} from './styles';
import { useAuth } from '../../../hooks/auth';
import api from '../../../services/api';
import IRequestError from '../../../dtos/IRequestError';
import { useToast } from '../../../hooks/toast';
import ITrailsProps from '../../../dtos/ITrailsProps';
import IChapterProps from '../../../dtos/IChapterProps';
import ChapterAccordion from '../../../components/ChapterAccordion';
import { ActivityIcon, ClockIcon } from '../../../assets/svg/SVGComponents';

const TrailsAndProgressMobile: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { isSessionExpired, user } = useAuth();
  const { t } = useTranslation();
  const { addToast } = useToast();
  const [trailsData, setTrailsData] = useState<ITrailsProps | null>(null);
  const [chaptersData, setChaptersData] = useState<IChapterProps[] | null>(
    null,
  );
  const [openPlaylistId, setOpenPlaylistId] = useState<string | null>(null);

  useEffect(() => {
    setIsLoading(true);

    api
      .get(
        `/trails/${user.current_activity.chapters[0].trails[0].slug}/progress`,
      )
      .then(response => {
        setTrailsData(response.data);
        setIsLoading(false);
      })
      .catch(async (err: IRequestError) => {
        if (!(await isSessionExpired(err))) {
          addToast({
            type: 'error',
            expression: 'Ops.',
            description: t('toasts.errors.load_data_fail'),
          });
        }
      });
  }, [addToast, t, user]);

  const handleProgressTrails = useCallback(
    (trail: ITrailsProps) => {
      let doneActivies = 0;
      trail.chapters.forEach(chapter => {
        if (!chapter.done_activities) {
          return;
        }
        if (!trailsData?.chapters.length) {
          return;
        }

        if (chapter.type === 'lesson') {
          doneActivies += chapter.done_activities;
        }
        if (chapter.type === 'activity') {
          doneActivies += chapter.done_activities;
        }
      });

      return Math.round((doneActivies / trail.chapters.length) * 100);
    },
    [trailsData],
  );

  const handleProgressChapters = useCallback(
    (chapter: IChapterProps) => {
      if (!chapter.done_activities) {
        return 0;
      }

      if (!trailsData?.chapters.length) {
        return 0;
      }
      let percentageCompleted = 0;
      if (chapter.type === 'lesson') {
        percentageCompleted =
          (chapter.done_activities / chapter.lessons.length) * 100;
      }
      if (chapter.type === 'activity') {
        percentageCompleted =
          (chapter.done_activities / chapter.activities.length) * 100;
      }

      return Math.round(percentageCompleted);
    },
    [trailsData],
  );

  const handleToggle = (playlistId: string): void => {
    setOpenPlaylistId(prevId => (prevId === playlistId ? null : playlistId));
  };

  useEffect(() => {
    if (trailsData && trailsData.chapters) {
      const lastViewedSlug = user.current_activity.chapters[0].slug;
      const lastChapter = trailsData.chapters.find(
        ch => ch.slug === lastViewedSlug,
      );
      const otherChapters = trailsData.chapters.filter(
        ch => ch.slug !== lastViewedSlug,
      );

      if (lastChapter) {
        handleToggle(lastChapter.id);
      }

      // Se o capítulo visualizado existir, o coloca no início; caso contrário, mantém a ordem original
      const orderedChapters = lastChapter
        ? [lastChapter, ...otherChapters]
        : trailsData.chapters;

      setChaptersData(orderedChapters);
    }
  }, [trailsData, user.current_activity]);

  return (
    <ContentWrapper>
      <MainContent>
        {trailsData && chaptersData && trailsData.type === 'chapter' && (
          <>
            <h2>A sua última trilha foi:</h2>
            <ChapterAccordion
              key={trailsData.id}
              title={t(`trails.${trailsData.slug}.title`)}
              progress={handleProgressTrails(trailsData)}
              videoAmount={trailsData.chapters.length}
              selected={openPlaylistId === trailsData.id}
              isOpen={openPlaylistId === trailsData.id}
              onToggle={() => handleToggle(trailsData.id)}
            >
              <ul>
                {trailsData.chapters.map(chapterItem => (
                  <TimelineItem
                    key={chapterItem.id}
                    watching={chapterItem.activities[0].watched}
                  >
                    <Link
                      data-testid="link-lesson"
                      to={`/projects/${trailsData.slug}/${chapterItem.id}/activity/${chapterItem.activities[0].slug}`}
                    >
                      <PlaylistCard disabled={false}>
                        <IconContent>
                          <img src={chapterItem.icon_url} alt="" />
                        </IconContent>
                        <ChapterInfos>
                          <h3>{t(`chapters.${chapterItem.slug}.title`)}</h3>

                          <span>
                            {t(
                              `chapters.${chapterItem.slug}.short_description`,
                            )}
                          </span>
                          <div>
                            <ClockIcon
                              width="1.2rem"
                              fill="none"
                              stroke="#1E88E5"
                            />
                            <span>{`${chapterItem.activities[0].duration} min`}</span>
                          </div>
                        </ChapterInfos>
                      </PlaylistCard>
                    </Link>
                  </TimelineItem>
                ))}
              </ul>
            </ChapterAccordion>
          </>
        )}

        {trailsData && chaptersData && trailsData.type === 'activity' && (
          <>
            <h2>O seu último capítulo foi:</h2>
            {chaptersData.map(chapterItem => (
              <ChapterAccordion
                key={chapterItem.id}
                title={t(`chapters.${chapterItem.slug}.title`)}
                progress={handleProgressChapters(chapterItem)}
                videoAmount={chapterItem.activities.length}
                selected={openPlaylistId === chapterItem.id}
                isOpen={openPlaylistId === chapterItem.id}
                onToggle={() => handleToggle(chapterItem.id)}
              >
                <ul>
                  {chapterItem.activities.map(activityItem => (
                    <TimelineItem
                      key={activityItem.id}
                      watching={activityItem.watched}
                    >
                      <Link
                        data-testid="link-lesson"
                        to={`/projects/${trailsData.slug}/${chapterItem.id}/activity/${activityItem.slug}`}
                      >
                        <PlaylistCard disabled={false}>
                          <IconContentActivity>
                            <ActivityIcon width="1.22rem" fill="#fff" />
                          </IconContentActivity>
                          <ChapterInfos>
                            <h3>
                              {t(`activities.${activityItem.slug}.title`)}
                            </h3>

                            <span>
                              {t(
                                `activities.${activityItem.slug}.short_description`,
                              )}
                            </span>
                            <div>
                              <ClockIcon
                                width="1.2rem"
                                fill="none"
                                stroke="#1E88E5"
                              />
                              <span>{`${activityItem.duration} min`}</span>
                            </div>
                          </ChapterInfos>
                        </PlaylistCard>
                      </Link>
                    </TimelineItem>
                  ))}
                </ul>
              </ChapterAccordion>
            ))}
          </>
        )}
      </MainContent>
    </ContentWrapper>
  );
};

export default TrailsAndProgressMobile;
