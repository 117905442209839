import { lighten } from 'polished';
import styled, { css } from 'styled-components';

interface TimelineItemProps {
  watching: boolean;
}

interface PlaylistCardProps {
  disabled?: boolean;
}

export const ContentWrapper = styled.div`
  display: flex;
`;

export const MainContent = styled.div`
  width: 100%;
`;

export const MainContentHeader = styled.header`
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;

  > a {
    display: flex;
    align-items: center;
    margin-bottom: 0.89rem;
    width: auto;
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.1rem;
    line-height: 1.61rem;
    opacity: 0.8;
    transition: opacity 0.3s;

    > svg {
      margin-right: 0.89rem;
      opacity: 0.8;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const TitleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    margin-right: 0.88rem;
  }

  h1 {
    font-family: 'RedHatDisplay-Extrabold';
    color: #1e88e5;
    font-size: 2rem;
    line-height: 2.55rem;
    margin-right: 0.88rem;
  }

  > div > svg {
    display: block;
    margin: auto;
    position: relative;
    cursor: pointer;
    transition: stroke 0.3s;
  }

  > div > svg {
    stroke: ${lighten(0.16, '#63646A')};

    &:hover {
      stroke: #63646a;
    }
  }

  p {
    font-family: 'RedHataDisplay-Regular';
    font-size: 1rem;
    line-height: 1.44rem;
    margin-left: 1.88rem;
  }
`;

export const ChaptersLists = styled.div`
  h2 {
    font-family: 'RedHatDisplay-ExtraBold';
    font-size: 1.22rem;
    margin-bottom: 3.11rem;
  }
`;

export const TimelineItem = styled.li<TimelineItemProps>`
  position: relative;
  margin-bottom: 1.5rem;

  > a {
    width: fit-content;
    height: fit-content;
  }

  &:before {
    content: '';
    background-color: ${props => (props.watching ? '#FF9800' : '#e2e2e2')};
    position: absolute;
    top: 70%;
    bottom: 0;
    left: 12px;
    width: 1px;
    height: 80%;
  }

  &:after {
    content: '';
    width: 12px;
    height: 12px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: ${props => (props.watching ? '#FF9800' : '#e2e2e2')};
    box-shadow: ${props =>
      props.watching ? ' 0px 0px 0px 0.5rem rgba(236, 119, 93, 0.1)' : '0'};
    position: absolute;
    left: 6.5px;
  }

  &:nth-last-child(2):before {
    background: #e2e2e2;
  }

  &:last-child:before {
    height: 0;
  }

  @media (max-width: 425px) {
    margin-left: 0;
    padding-left: 0.8rem;

    &:before {
      width: 1px;
      height: 80%;
      left: 9px;
    }

    &:after {
      width: 8px;
      height: 8px;
      left: 6px;
    }
  }
`;

const playlisCardStyle = {
  disabled: css``,
  enabled: css`
    box-shadow: 4px 8px 15px rgba(0, 0, 0, 0.16);
    transform: translateY(-5px);
  `,
};

export const PlaylistCard = styled.div<PlaylistCardProps>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  width: 90%;
  height: 7.5rem;
  margin-left: 2.5rem;
  padding-left: 1.22rem;
  border-radius: 1.1rem;
  display: flex;
  align-items: center;
  gap: 1;
  background: ${props => (props.disabled ? '#f5f5f5' : '#fff')};
  /* box-shadow: 0px 3px 10px #00000029; */
  position: relative;
  transition: all 0.3s;

  &:hover {
    ${props =>
      props.disabled ? playlisCardStyle.disabled : playlisCardStyle.enabled}
  }

  @media (max-width: 500px) {
    margin-left: 1.2rem;
    width: 90%;
  }
`;

export const IconContent = styled.div`
  width: 2.33rem;
  height: 2.33rem;
  border-radius: 50%;
  background-color: #4caf50;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  @media (max-width: 540px) {
    > svg {
      width: 1rem;
    }
  }
`;

export const HeaderCard = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.56rem;
`;

export const VideoInfos = styled.div`
  flex: 1;
  min-width: 0;
  margin-left: 2.22rem;
  margin-right: 3rem;
  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.38rem;
    font-family: 'RedHatDisplay-Bold';
    line-height: 1.38rem;
    color: #1e88e5;
    margin-bottom: 0.83rem;
  }

  > span {
    font-size: 0.89rem;
    margin-bottom: 0.83rem;
  }

  > div {
    display: flex;
    align-items: center;

    > span {
      margin-left: 0.56rem;
      font-family: 'RedHatDisplay-Bold';
      font-size: 0.83rem;
    }
  }

  @media (max-width: 700px) {
    margin-left: 1.6rem;
    margin-right: 0;
  }

  @media (max-width: 540px) {
    margin-left: 1.6rem;
    margin-right: 0;

    h3 {
      font-size: 1.2rem;
      font-family: 'RedHatDisplay-Bold';
      line-height: 1rem;
      margin-bottom: 0.3rem;
    }
  }

  @media (max-width: 500px) {
    margin-left: 1.1rem;
    margin-right: 0;

    h3 {
      font-size: 1rem;
      font-family: 'RedHatDisplay-Bold';
    }
  }
`;
