import i18next from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MarkdownIt from 'markdown-it';
import { useToast } from '../../../../hooks/toast';
import IActivityProps from '../../../../dtos/IActivitiesProps';
import { ResourceType, StepType } from '../../../../utils/enums';
import { Container, TextContent, VideoContent, VideoWrapper } from './styles';
import IStepProps from '../../../../dtos/IStepsProps';
import VideoPlayer from '../../../../components/VideoPlayer';

const keywordColors: { [key: string]: string } = {
  basic: '#1e90ff',
  input: '#d400d4',
  music: '#e63022',
  led: '#5c2d91',
  radio: '#E3008C',
  variables: '#DC143C',
  game: '#007A4B',
  logic: '#00A4A6',
};

const md = new MarkdownIt();

md.use(markdown => {
  const defaultRender = markdown.renderer.rules.code_inline;

  markdown.renderer.rules.code_inline = (tokens, idx, options, env, self) => {
    const token = tokens[idx];
    let color = '';
    const text = token.content.replace(
      /\|\|(.*?):(.+?)\|\|/g,
      (match, p1, p2) => {
        color = keywordColors[p1.trim()] || 'transparent';
        token.attrJoin('style', `background-color: ${color}; `);
        return `${p2}`;
      },
    );
    token.content = text;
    token.markup = '';
    return defaultRender
      ? defaultRender(tokens, idx, options, env, self)
      : token.content;
  };
});

interface IComponentProps {
  currentActivity: IActivityProps;
  currentStep: IStepProps;
}

const MarkdownComponent: React.FC<IComponentProps> = ({
  currentStep: step,
  currentActivity,
}) => {
  const [descriptionText, setDescriptionText] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const slice = location.pathname.split('/');
  const active_tab = slice[slice.length - 1] as StepType;

  const { addToast } = useToast();
  const { t } = useTranslation();

  const loadDescriptionMarkdown = useCallback(
    async (currentStep: IStepProps) => {
      let descriptionURL = '';

      if (currentStep && step.resourse_type === ResourceType.MARKDOWN) {
        if (i18next.language === 'en') {
          descriptionURL = step.resource_url_en;
        }

        if (i18next.language === 'pt-BR') {
          descriptionURL = step.resource_url;
        }
      }

      if (!descriptionURL) {
        // Não prossiga se a URL não foi definida
        return;
      }

      try {
        const response = await fetch(descriptionURL);

        const text = await response.text();

        const html = md.render(text);

        setDescriptionText(html);
      } catch (error) {
        addToast({
          type: 'error',
          expression: 'Ops!',
          description: t('toasts.errors.load_description_fail'),
        });
      }
    },
    [addToast, step, t],
  );

  useEffect(() => {
    if (step) {
      loadDescriptionMarkdown(step);
    }
  }, [loadDescriptionMarkdown, step]);

  const handleAccesVideoButton = useCallback(() => {
    if (currentActivity && active_tab) {
      if (currentActivity.resource_url != null) {
        navigate(currentActivity.resource_url);
      }
    }
  }, [active_tab, currentActivity, navigate]);

  return (
    <Container>
      <VideoWrapper>
        {step?.optional_resource_url && (
          <VideoContent>
            <VideoPlayer url={step.optional_resource_url} />
          </VideoContent>
        )}
      </VideoWrapper>

      <TextContent>
        {currentActivity && (
          <div>
            <div
              className="markdown-container"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: descriptionText }}
            />
          </div>
        )}
      </TextContent>
    </Container>
  );
};

export default MarkdownComponent;
