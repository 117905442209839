import styled from 'styled-components';
import { shade, lighten } from 'polished';

interface TabContentProps {
  index: number;
}

interface UserAvatarProps {
  avatarSrc: string;
}

interface SchoolLogoProps {
  logoUrl: string;
}

export const MainContent = styled.main`
  display: flex;
  align-items: stretch;
  width: 100%;
  font-size: 1rem;
`;

export const Dashboard = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2.8rem 3.8rem;

  > a:first-child {
    background: none;
    display: flex;
    align-items: center;
    color: #1e88e5;
    text-align: center;
    margin-bottom: 3.5rem;
    font-family: 'RedhatDisplay-Regular';
    font-size: 1.1em;
    width: fit-content;
    transition: opacity 0.3s;
    opacity: 0.8;

    svg {
      margin-right: 0.85em;
    }

    &:hover {
      opacity: 1;

      > svg {
        opacity: 1;
      }
    }
  }
`;

export const WelcomeSection = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.9rem;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);

  @media (max-width: 768px) {
    flex-direction: column;
  }

  @media (max-width: 600px) {
  }
`;

export const UnlockButton = styled.div`
  display: flex;
  align-items: center;

  > .primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: #fff;
    line-height: 1.28rem;
    padding: 0.77rem 1rem;
    border-radius: 0.55rem;
    min-width: 10.57rem;
    min-height: 2.82rem;
    box-shadow: 0px 3px 6px rgba(255, 159, 0, 0.3);
  }
  > div {
    margin-left: 1.72rem;
  }

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

export const NameAndAvatarWrapper = styled.div`
  display: flex;
  align-items: center;

  > span {
    display: flex;
    flex-direction: column;

    h1 {
      font-family: 'RedhatDisplay-Medium';
      font-size: 2.33rem;
      line-height: 3rem;
      margin-bottom: 0.22rem;
    }

    h1 b {
      font-family: 'RedhatDisplay-Medium';
    }

    > div {
      display: flex;
      align-items: center;

      p {
        font-size: 1rem;
        line-height: 1.3rem;
        font-family: 'RedhatDisplay-Regular';
        color: #1e88e5;
        margin-left: 0.61rem;
      }
    }
  }
`;

export const AvatarInput = styled.div`
  width: 6.5rem;
  height: 6.5rem;
  flex-shrink: 0;
  padding: 0.33rem;
  border-radius: 50%;
  border: 3px solid #ff9800;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2.38rem;
  position: relative;

  > button {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 4.2rem;
    left: 0.3rem;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    visibility: hidden;
    opacity: 0;
    transition: background 0.3s;

    > svg {
      visibility: hidden;
    }

    &:hover {
      background: ${shade(0.2, '#fff')};
      svg {
        stroke: '#FF2E3E';
      }
    }

    @media (max-width: 768px) {
      width: 1rem;
      height: 1rem;
    }

    @media (max-width: 600px) {
    }
  }

  label {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -0.5rem;
    right: -3px;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background: ${shade(0.2, '#fff')};
    }

    input {
      display: none;
    }
  }

  &:hover {
    button {
      opacity: 1;
      visibility: visible;
      transition: opacity 0.3s;
      > svg {
        visibility: visible;
      }
    }
  }
`;

export const UserAvatar = styled.div<UserAvatarProps>`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  background: url(${props => props.avatarSrc}) no-repeat center;
  background-size: cover;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SchoolLogo = styled.div<SchoolLogoProps>`
  width: 1.8em;
  height: 1.8em;
  border-radius: 50%;
  border: 1px solid rgba(50, 54, 80, 0.1);
  background: url(${props => props.logoUrl}) no-repeat center;
  background-size: cover;
`;

export const PerformanceCardWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 1.11rem;

  > div:last-child {
    margin-left: 1.1rem;

    > svg {
      display: block;
      margin: auto;
      position: relative;
      cursor: pointer;
      transition: stroke 0.3s;
      stroke: ${lighten(0.16, '#63646A')};

      &:hover {
        stroke: #63646a;
      }
    }
  }
`;

export const PerformanceCard = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #e2e2e2;
  border-radius: 0.78rem;
  background: #fff;
  color: #323650;
  padding: 1.22rem 2.5rem 1.22rem 1.9rem;

  p {
    font-size: 1em;
    font-family: 'MarkPro-Medi';
    line-height: 1.33rem;
    margin-right: 0.67rem;
  }

  > svg {
    margin-right: 0.29rem;

    &:last-child {
      margin: 0;
    }
  }
`;

export const LastAccess = styled.p`
  font-family: 'RedHatDisplay-Regular';
  font-size: 1rem;
  line-height: 1.33rem;
`;

export const NavigationMenu = styled.div`
  padding-top: 5.24vh;

  .profile-navBar {
    background: none;
    border: 0;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    box-shadow: none;
    margin-bottom: 6.11vh;
    width: 100%;
  }

  .profile-tabs-container {
    overflow: visible !important;
  }

  .MuiTabs-scroller.MuiTabs-fixed {
    overflow: visible !important;
  }

  .profile-tab-item {
    > span {
      font-family: 'RedHatDisplay-Regular';
      font-size: 1.22rem;
      color: #1e88e5; !important;
      text-transform: none;
    }
  }
`;

export const TabContent = styled.div<TabContentProps>`
  width: 100px;
  height: 100px;
`;
