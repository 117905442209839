import styled, { css, keyframes } from 'styled-components';
import DotIcon from '../../../../assets/svg/icons/dot.svg';

interface IDotAccomplished {
  accomplished: boolean;
  width: 'small' | 'medium' | 'large';
}

interface ShowButton {
  hover: boolean;
}

interface ImageSource {
  isTall: boolean;
  isBlur: boolean;
}

interface PropsWidth {
  width: 'small' | 'medium' | 'large';
}

interface TooltipSlide {
  x: number;
  y: number;
}

interface lengthContent {
  length: number;
}

interface SizeDotsArea {
  dots: number;
}

const typing = keyframes`
from {width: 0}
 to{width: 100%}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const calculateLineHeight = (contentLength: number): string => {
  if (contentLength <= 200) {
    return '1.5rem'; // tighter spacing for shorter content
  }
  if (contentLength <= 400) {
    return '1.75rem'; // medium spacing for medium content
  }
  return '1.2rem'; // wider spacing for longer content
};

const calculateFontSize = (titleLength: number): string => {
  if (titleLength <= 20) {
    return '2em'; // larger font size for short titles
  }
  if (titleLength <= 40) {
    return '1.75em'; // medium font size
  }
  return '1.5em'; // smaller font size for long titles
};

export const TypingAnmation = styled.div`
  width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  font-size: 20px;
`;

export const Typing = styled.div`
  width: 100%;
  animation: ${typing} 3s steps(40, end);
`;

export const Container = styled.div`
  width: 100%;
  padding-bottom: 10rem;
`;

export const ContainerWrapper = styled.div`
  width: 100%;
  position: relative;
`;

export const AccordionContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55),
    opacity 0.5s ease;
  overflow: hidden;
  padding: 0;
  max-height: 0;

  &.collapsed {
    transform: scaleY(0);
    opacity: 0;
    padding: 0;
    max-height: 0;
  }

  &:not(.collapsed) {
    transform: scaleY(1);
    max-height: 100%;
    opacity: 1;
    padding: 0 1rem 1rem 1rem;
  }
`;

export const ContainerContentWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute; // Alterado para absolute
  bottom: 0; // Posiciona na base do container pai
  left: 0;
  width: 100%;
  height: ${props => (props.isOpen ? '100%' : '20%')};
  background-color: rgba(217, 217, 217, 0.6);
  border-radius: 1rem; // Borda arredondada apenas no topo
  z-index: 1000;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
  overflow: hidden; // Garante que o conteúdo não ultrapasse os limites
`;

export const ContentHeader = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > div {
    margin-bottom: 0.8rem;
  }

  .chevron {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: transform 0.3s ease;
    margin-top: 1.4rem;

    svg {
      transform: rotate(180deg);
      transition: transform 0.3s ease;
      &.open {
        transform: rotate(0deg);
      }
    }
  }
`;

export const Title = styled.h1`
  font-family: 'MarkPro-Heavy';
  font-size: 2rem;
  overflow-wrap: break-word; // Quebra palavras longas para evitar overflow
  word-break: break-word; // Quebra palavras no limite do contêiner
  // Permite quebras de linha e preserva espaços em branco
  box-sizing: border-box; // Inclui bordas no cálculo do tamanho
  margin-bottom: 0.1rem;
  color: #1e88e5;
`;

export const Content = styled.div<lengthContent>`
  max-width: 100%;
  max-height: 100%;
  line-height: ${props => calculateLineHeight(props.length)};
  padding: 1rem;

  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* para scroll suave em iOS */
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(217, 217, 217, 0.8);
    border-radius: 4px;
    /* margin: 8px 0; separa a scrollbar das bordas do container */
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.4);
    border-radius: 4px;
  }

  text-overflow: ellipsis;
  h1 {
    font-family: 'RedHatDisplay-Heavy';
    font-size: 1.56rem;
    margin-bottom: 2rem;
  }

  h2 {
    font-family: 'RedHatDisplay-Heavy';
    font-size: 1.44rem;
    margin-bottom: 1.8rem;
  }

  h3 {
    font-family: 'RedHatDisplay-Heavy';
    font-size: 1.33rem;
    margin-bottom: 1.8rem;
  }

  p {
    font-family: 'RedHatDisplay-Medium';
    font-size: 1rem;
    line-height: ${props => calculateLineHeight(props.length)};
    margin-bottom: 0.8rem;
  }

  strong {
    font-family: 'MarkPro-Heavy';
  }

  code {
    font-family: 'RedHatDisplay-Medium';
    padding: 0.05rem 0.2rem;
    border-radius: 0.2rem;
    line-height: ${props => calculateLineHeight(props.length)};
  }

  a {
    font-family: 'MarkPro-Bold';
    color: #4caf50;

    &:hover {
      text-decoration: underline;
    }
  }

  img {
    display: flex;
    max-width: 100%;
    border-radius: 1.11rem;
    margin: 1rem auto;
  }

  ul {
    margin-bottom: 1.5rem;
    padding-left: 0.5rem;
  }

  li {
    font-family: 'RedHatDisplay-Medium';
    background: url(${DotIcon}) no-repeat left 5px center;
    line-height: 1.4rem;
    padding-left: 1.3rem;
  }
`;

export const ImageSlide = styled.img<ImageSource>`
  width: 100%;
  height: 100%;
  object-fit: ${props => (props.isTall ? 'contain' : 'cover')};
  object-position: center;
  animation: ${fadeIn} 0.3s ease;
  filter: ${props => (props.isBlur ? 'blur(4px)' : 'none')};
  transition: filter 0.3s ease;
`;

export const ImageContainer = styled.div`
  min-height: 55vh;
  position: relative;
  flex: 1;
  border: 2px solid #e2e2e2;
  border-radius: 1rem 1rem 1rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.4s ease;
  background-color: #fff;
`;

export const ButtonContainer = styled.div<ShowButton>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: space-between;
  top: 0;
  bottom: 0;

  opacity: ${props => (props.hover ? 1 : 0)};
  transition: opacity 0.3s ease;

  button {
    border-radius: 50%;
    position: absolute;
    width: 2rem;
    height: 2rem;
    background-color: #1e88e5;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      top: 50%;
      transform: translateY(-50%);
      left: 2%;
    }

    &:last-child {
      top: 50%;
      transform: translateY(-50%);
      right: 2%;
    }
  }
`;

export const Progress = styled.div<PropsWidth>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;

  > button {
    border-radius: 50%;
    background-color: #1e88e5;
    ${props => SlideButtonProps[props.width]}
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:first-child {
      margin-right: 0.5rem;
    }

    &:last-child {
      margin-left: 0.5rem;
    }
  }
`;

export const DotSlides = styled.div<SizeDotsArea>`
  max-width: ${props => props.dots * 1.1}rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(0.9rem, 1fr));
  grid-gap: 0.3rem;
  justify-content: center;
`;

export const Dot = styled.div<PropsWidth>`
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  ${props => SlideDotsProps[props.width || 'small']}

  background-color: #e2e2e2;
  border-radius: 50%;

  position: relative;
`;

export const DotAccomplished = styled.div<IDotAccomplished>`
  ${props => SlideDoneDotsProps[props.width || 'small']}
  border-radius: 50%;
  background-color: ${props => (props.accomplished ? '#4CAF50' : '#e2e2e2')};
  transition: background-color 0.4s ease;
`;

export const TooltipArea = styled.div`
  position: relative;
`;

export const Tooltip = styled.div<TooltipSlide>`
  position: absolute;
  /* top: ${props => props.y}px; */
  left: ${props => props.x}px; // Posição horizontal
  transform: translate(-50%, -100%); // Centraliza o tooltip acima do ponteiro
  font-family: 'MarkPro';
  background-color: #fffeff;
  color: #1e88e5;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
  white-space: nowrap;
  z-index: 10000;
  font-size: 0.8rem;
  /* visibility: hidden; */
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
`;

const SlideContentProps = {
  small: css`
    width: 30%;
  `,

  medium: css`
    width: 40%;
  `,

  large: css`
    width: 50%;
  `,
};

const SlideButtonProps = {
  small: css`
    height: 1.4rem;
    width: 1.4rem;

    > svg {
      width: 0.8rem;
      stroke: #fff;
      stroke-width: 2.8;
      fill: none;
    }
  `,

  medium: css`
    width: 1.6rem;
    height: 1.6rem;

    > svg {
      width: 1rem;
      stroke: #fff;
      stroke-width: 3;
      fill: none;
    }
  `,

  large: css`
    width: 2rem;
    height: 2rem;

    > svg {
      width: 1.22rem;
      stroke: #fff;
      stroke-width: 4;
      fill: none;
    }
  `,
};

const SlideDotsProps = {
  small: css`
    width: 0.6rem;
    height: 0.6rem;
  `,

  medium: css`
    width: 0.8rem;
    height: 0.8rem;
  `,

  large: css`
    width: 1rem;
    height: 1rem;
  `,
};

const SlideDoneDotsProps = {
  small: css`
    width: 0.45rem;
    height: 0.45rem;
  `,

  medium: css`
    width: 0.65rem;
    height: 0.65rem;
  `,

  large: css`
    width: 0.85rem;
    height: 0.85rem;
  `,
};
