import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useState,
  useRef,
  useEffect,
} from 'react';

export interface DropdownHandles {
  toggleDropdown: () => void;
  closeDropdown: () => void;
}

const Dropdown: React.ForwardRefRenderFunction<DropdownHandles, ReactNode> = (
  { children },
  ref,
) => {
  const refDropdown = useRef<HTMLElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  const toggleDropdown = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  const openDropdown = useCallback(() => {
    setIsVisible(true);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsVisible(false);
  }, []);

  useImperativeHandle(ref, () => {
    return {
      toggleDropdown,
      openDropdown,
      closeDropdown,
    };
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      // Verifica se o clique foi fora do dropdown
      if (
        refDropdown.current &&
        !refDropdown.current.contains(event.target as Node)
      ) {
        closeDropdown(); // Fecha o dropdown
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    // Remove o event listener quando o componente é desmontado
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeDropdown]);

  return (
    <nav ref={refDropdown} className={isVisible ? 'visible' : 'hidden'}>
      {children}
    </nav>
  );
};

export default forwardRef(Dropdown);
