import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const RewindVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 81 51">
    <path d="M39.92 30.22c-3.995-2.32-3.979-8.094.027-10.392l19.53-11.206c4.007-2.299 8.999.601 8.986 5.22l-.06 22.516c-.012 4.62-5.02 7.493-9.014 5.173L39.919 30.22Z" />
    <path d="M14.92 30.22c-3.995-2.32-3.979-8.094.027-10.392l19.53-11.206c4.007-2.299 8.999.601 8.986 5.22l-.06 22.516c-.012 4.62-5.02 7.493-9.014 5.173L14.919 30.22Z" />
    <rect
      width={15}
      height={38}
      x={15}
      y={44.134}
      rx={6}
      transform="rotate(-180 15 44.134)"
    />
  </svg>
);
export default RewindVideoIcon;
