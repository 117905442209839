import React, { ChangeEvent, useEffect, useRef, useState } from 'react';

import ReactPlayer from 'react-player';
import { useMediaQuery } from 'react-responsive';
import {
  BottomControlContainer,
  ButtonControlBar,
  ButtonControlsSection,
  Container,
  ControlOverlay,
  MidControlContainer,
  PlayPauseButton,
  ProgressBarContainer,
  SeekButton,
  SliderContainer,
  SpeedControl,
  VolumeContainer,
  VolumeSlider,
} from './styles';
import {
  FowardVideoIcon,
  FullscreenIcon,
  PauseVideoIcon,
  PlayVideoIcon,
  RewindVideoIcon,
  SoundMuteVideoIcon,
  SoundVideoIcon,
} from '../../assets/svg/SVGComponents';

interface VideoPlayerProps {
  url?: string; // URL do vídeo no bucket da AWS
  // thumbnail: string;
}

interface VideoState {
  playing: boolean;
  muted: boolean;
  volume: number;
  playbackRate: number;
  speed: number;
  played: number;
  seeking: boolean;
  buffer: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ url }) => {
  const playerRef = useRef<ReactPlayer>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [isHoveredMid, setIsHoveredMid] = useState(false);
  const [isHoveredBottom, setIsHoveredBottom] = useState(false);
  const [started, setStarted] = useState(false);
  const [videoState, setVideoState] = useState<VideoState>({
    playing: false,
    muted: false,
    volume: 0.5,
    playbackRate: 1.0,
    speed: 1.0,
    played: 0,
    seeking: false,
    buffer: true,
  });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const [isMobile, setIsMobile] = useState(false);

  // Controle de visibilidade dos controles para mobile
  const [controlsVisible, setControlsVisible] = useState(true);
  // Estado para tela cheia
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Referência para o timeout que esconde os controles
  const hideControlsTimeout = useRef<ReturnType<typeof setTimeout> | null>(
    null,
  );

  useEffect(() => {
    if (isTabletOrMobile || isPortrait) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [isPortrait, isTabletOrMobile]);

  // Função para lidar com toques em dispositivos mobile para mostrar/ocultar os controles
  const handleTouch = (e: React.TouchEvent): void => {
    const target = e.target as HTMLElement;
    if (target.closest('.control-element')) return;

    // Lógica original de alternar controles
    if (controlsVisible) {
      setControlsVisible(false);
      if (hideControlsTimeout.current)
        clearTimeout(hideControlsTimeout.current);
    } else {
      setControlsVisible(true);
      resetHideControlsTimeout();
    }
  };

  const resetHideControlsTimeout = (): void => {
    if (hideControlsTimeout.current) clearTimeout(hideControlsTimeout.current);
    hideControlsTimeout.current = setTimeout(() => {
      setControlsVisible(false);
    }, 3000);
  };

  const toggleFullScreen = (): void => {
    if (!document.fullscreenElement && containerRef.current) {
      containerRef.current.requestFullscreen();
      setIsFullscreen(true);
    } else {
      document.exitFullscreen();
      setIsFullscreen(false);
    }
  };

  // Desestruturação das propriedades do estado
  const { playing, muted, volume, playbackRate } = videoState;

  // Obtendo o tempo atual e a duração
  const currentTime: number = playerRef.current
    ? playerRef.current.getCurrentTime()
    : 0;
  const duration: number = playerRef.current
    ? playerRef.current.getDuration()
    : 0;

  // Função para formatação do tempo (exemplo: mm:ss)
  const formatTime = (time: number): string => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const formattedCurrentTime = formatTime(currentTime);
  const formattedDuration = formatTime(duration);

  // Alterna entre play e pause
  const playPauseHandler = (): void => {
    setVideoState({ ...videoState, playing: !videoState.playing });
    setStarted(true);
  };

  const handlePlaybabckrate = (
    e: React.ChangeEvent<HTMLSelectElement>,
  ): void => {
    setVideoState({ ...videoState, playbackRate: parseFloat(e.target.value) });
  };

  // Retrocede 5 segundos no vídeo
  const rewindHandler = (): void => {
    if (playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() - 5);
    }
  };

  // Avança 10 segundos no vídeo
  const handleFastFoward = (): void => {
    if (playerRef.current) {
      playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
    }
  };

  // Handler para atualizar o progresso e controlar a visibilidade dos controles
  const progressHandler = (state: Partial<VideoState>): void => {
    if (!videoState.seeking) {
      setVideoState({ ...videoState, ...state });
    }
  };

  // Atualiza o progresso conforme a interação do usuário
  const seekHandler = (e: MouseEvent | ChangeEvent, value: number): void => {
    const newPlayed = parseFloat((value / 100).toString());
    setVideoState({ ...videoState, played: newPlayed });
    if (playerRef.current) {
      playerRef.current.seekTo(newPlayed);
    }
  };

  // Handler para quando o usuário solta o controle de seek
  const seekMouseUpHandler = (
    e: React.MouseEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement>,
    value: number,
  ): void => {
    setVideoState({ ...videoState, seeking: false });
    if (playerRef.current) {
      playerRef.current.seekTo(value / 100);
    }
  };

  const volumeChangeHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const value = Number(e.target.value);
    const newVolume = value; // Supondo que o range de volume seja de 0 a 1
    setVideoState(prev => ({
      ...prev,
      volume: newVolume,
      muted: newVolume === 0,
    }));
  };

  // Handler para quando o usuário solta o controle de volume
  const volumeSeekUpHandler = (e: React.MouseEvent<HTMLInputElement>): void => {
    const value = Number((e.target as HTMLInputElement).value);
    const newVolume = value; // Supondo que o range de volume seja de 0 a 1
    setVideoState(prev => ({
      ...prev,
      volume: newVolume,
      muted: newVolume === 0,
    }));
  };

  // Alterna o mudo
  const muteHandler = (): void => {
    setVideoState({ ...videoState, muted: !videoState.muted });
  };

  // Handler chamado quando o usuário começa a arrastar o controle de seek
  const onSeekMouseDownHandler = (): void => {
    setVideoState({ ...videoState, seeking: true });
  };

  // Exibe os controles quando o mouse se move

  // Handlers para início e fim de buffering
  const bufferStartHandler = (): void => {
    console.log('Buffering.......');
    setVideoState({ ...videoState, buffer: true });
  };

  const bufferEndHandler = (): void => {
    console.log('Buffering parado, play');
    setVideoState({ ...videoState, buffer: false });
  };

  return (
    <Container ref={containerRef} onTouchStart={handleTouch}>
      <ReactPlayer
        ref={playerRef}
        className="react-player"
        url={url}
        width="100%"
        height="100%"
        playing={playing}
        playbackRate={playbackRate}
        volume={volume}
        muted={muted}
        onProgress={progressHandler}
        onBuffer={bufferStartHandler}
        onBufferEnd={bufferEndHandler}
        config={{
          file: {
            attributes: {
              controlsList: 'nodownload',
            },
          },
        }}
      />
      {/* Barra de controle customizada */}
      {(!isMobile || controlsVisible) && (
        <ControlOverlay className="controls-overlay">
          <MidControlContainer
            isPaused={!videoState.playing}
            isHovered={isHoveredMid}
            onMouseEnter={() => setIsHoveredMid(true)}
            onMouseLeave={() => setIsHoveredMid(false)}
          >
            <SeekButton
              className="control-element"
              type="button"
              onClick={rewindHandler}
            >
              <RewindVideoIcon width="2rem" height="2rem" fill="#fff" />
            </SeekButton>
            <PlayPauseButton
              className="control-element"
              isPlaying={videoState.playing}
              type="button"
              onClick={() => playPauseHandler()}
            >
              {!started || !videoState.playing ? (
                <PlayVideoIcon width="2rem" height="2rem" fill="#fff" />
              ) : (
                <PauseVideoIcon width="2rem" height="2rem" fill="#fff" />
              )}
            </PlayPauseButton>
            <SeekButton
              className="control-element"
              type="button"
              onClick={() => handleFastFoward()}
            >
              <FowardVideoIcon width="2rem" height="2rem" fill="#fff" />
            </SeekButton>
          </MidControlContainer>

          <BottomControlContainer
            isPaused={!videoState.playing}
            isHovered={isHoveredBottom || isHoveredMid}
            onMouseEnter={() => setIsHoveredBottom(true)}
            onMouseLeave={() => setIsHoveredBottom(false)}
          >
            <ProgressBarContainer
              value={videoState.played * 100}
              onChange={e => seekHandler(e, e.target.valueAsNumber)}
              onMouseDown={() => onSeekMouseDownHandler()}
              onMouseUp={e =>
                seekMouseUpHandler(
                  e,
                  (e.target as HTMLInputElement).valueAsNumber,
                )}
            />

            <ButtonControlsSection>
              <div>
                <ButtonControlBar
                  className="control-element"
                  onClick={() => playPauseHandler()}
                >
                  {!started || !videoState.playing ? (
                    <PlayVideoIcon
                      width="1.2rem"
                      height="1.2rem"
                      fill="#1e88e5"
                    />
                  ) : (
                    <PauseVideoIcon
                      width="1.2rem"
                      height="1.2rem"
                      fill="#1e88e5"
                    />
                  )}
                </ButtonControlBar>

                <span>
                  {formattedCurrentTime} / {formattedDuration}
                </span>
                <VolumeContainer className="control-element">
                  <button type="button" onClick={muteHandler}>
                    {videoState.volume > 0 && !videoState.muted ? (
                      <SoundVideoIcon
                        width="1.2rem"
                        height="1.2rem"
                        fill="#1e88e5"
                      />
                    ) : (
                      <SoundMuteVideoIcon
                        width="1.2rem"
                        height="1.2rem"
                        fill="#1e88e5"
                      />
                    )}
                  </button>

                  <VolumeSlider
                    type="range"
                    min="0"
                    max="1"
                    step="0.01"
                    value={videoState.volume}
                    onChange={e => volumeChangeHandler(e)}
                    onMouseUp={e => volumeSeekUpHandler(e)}
                  />
                </VolumeContainer>
              </div>
              <div>
                <SpeedControl
                  className="control-element"
                  value={videoState.playbackRate}
                  onChange={e => handlePlaybabckrate(e)}
                >
                  <option value="0.5">0.5x</option>
                  <option value="1">1x</option>
                  <option value="1.5">1.5x</option>
                  <option value="2">2x</option>
                </SpeedControl>
                {isMobile && (
                  <button
                    className="control-element"
                    type="button"
                    onClick={toggleFullScreen}
                    style={{ marginLeft: '0.5rem' }}
                  >
                    <FullscreenIcon
                      width="2rem"
                      height="2rem"
                      stroke="#1e88e5"
                    />
                  </button>
                )}
              </div>
            </ButtonControlsSection>

            <SliderContainer />
          </BottomControlContainer>
        </ControlOverlay>
      )}
      {/* Botão de controle no canto inferior direito */}
    </Container>
  );
};

export default VideoPlayer;
