import * as React from 'react';
import { SVGProps } from 'react';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const PauseVideoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
    <rect width={20} height={50} rx={6} />
    <rect width={20} height={50} x={30} rx={6} />
  </svg>
);
export default PauseVideoIcon;
